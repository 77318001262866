import { Content } from './styles';
import styled from "styled-components";
import oldFrame from '../../assets/BACKGROUND1.svg'
import homePicture from '../../assets/homePicture.svg'
import Notebook1 from '../../assets/Notebook1.svg'
import Notebook2 from '../../assets/Notebook2.svg'
import Notebook3 from '../../assets/Notebook3.svg'
import Notebook4 from '../../assets/Notebook4.svg'
import Suitcase from '../../assets/Suitcase.svg'
import OpenedSuitcase from '../../assets/OpenedSuitcase.svg'
import frameShh from '../../assets/frameShh.svg'
import Play from '../../assets/Play.svg'
import ConArtist from '../../assets/ConArtist.svg'
import ConArtist2 from '../../assets/ConArtist2.svg'
import DenOfThieves from '../../assets/DenOfThieves.svg'
import ScamOMatic from '../../assets/ScamOMatic.svg'
import TheLouvre from '../../assets/TheLouvre.svg'
import OneBg from '../../assets/Louvre/1Bg.svg';
import RobAChance from '../../assets/RobAChanceBG.svg';



import { useState, useEffect } from 'react';

export default function Frame(props: any) {
    const [backgroundImage, setBackgroundImage] = useState(homePicture)

    

    const Style = {
        "width": "100%",
        "backgroundImage": `url(${backgroundImage}), url(${oldFrame})`,
        "backgroundRepeat": "no-repeat",
        "backgroundPosition": "center",
        "height": "680px",
        "margin": "2rem 0",
        
    }


    useEffect(() => {
        switch(props.data){
        case 'homePicture':
            setBackgroundImage(homePicture)
            break;
        case 'Notebook1':
            setBackgroundImage(Notebook1)
            break;
        case 'Notebook2':
            setBackgroundImage(Notebook2)
            break;
        case 'Notebook3':
            setBackgroundImage(Notebook3)
            break;
        case 'Notebook4':
            setBackgroundImage(Notebook4)
            break;
        case 'Suitcase':
            setBackgroundImage(Suitcase)
            break;
        case 'OpenedSuitcase':
            setBackgroundImage(OpenedSuitcase)
            break;
        case 'Shh':
            setBackgroundImage(frameShh)
            break;
        case 'Play':
            setBackgroundImage(Play)
            break;
        case 'ConArtist':
            setBackgroundImage(ConArtist)
            break;
        case 'ConArtist2':
            setBackgroundImage(ConArtist2)
            break;
        case 'DenOfThieves':
            setBackgroundImage(DenOfThieves)
            break;
        case 'ScamOMatic':
            setBackgroundImage(ScamOMatic)
            break;
        case 'TheLouvre':
            setBackgroundImage(TheLouvre)
            break;
        case 'RobAChance':
            setBackgroundImage(RobAChance)
            break;
        default:
            setBackgroundImage(OneBg)
            break;
        
        }

    }, [props])

    return (
        <>
        <div style={Style}>
            {props.children}
        </div>
            
        </>
    )
}