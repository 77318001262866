import BigNumber from 'bn.js'
import { AbiItem } from "web3-utils"
import useLocalStorage from '../LocalStorage/useLocalStorage'

import web3 from '../utils/web3'
import ERC20ABI from "../constants/abi/ERC20.json"
import web3event from '../utils/web3event'


const useERC20Contract = () => {
  const [userWalletAddress, setUserWalletAddress] = useLocalStorage('userAddress', '')

  const sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
  
  const waitTransaction = async (txHash: string) => {
    let txReceipt: any = null;
    while (txReceipt === null) {
      const r = await web3.eth.getTransactionReceipt(txHash);
      txReceipt = r;
      await sleep(2000);
    }
    return txReceipt.status;
  };
  
  const approve = async (
    spenderAddress: string,
    tokenAddress: string,
  ): Promise<boolean> => {
    console.log(userWalletAddress)
    try {
      const tokenContract = getERC20Contract(tokenAddress);
      return tokenContract.methods
        .approve(spenderAddress, web3.utils.toTwosComplement(-1))
        .send({ from: userWalletAddress, maxPriorityFeePerGas: null,
          maxFeePerGas: null }, async (error: any, txHash: string) => {
          if (error) {
            console.log("ERC20 could not be approved", error);
            return false;
          }
          const status = await waitTransaction(txHash);
          if (!status) {
            console.log("Approval transaction failed.");
            return false;
          }
          return true;
        });
    } catch (e) {
      console.log("error", e);
      return false;
    }
  };
  

  const getERC20Contract = (address: string) => {
    const contract = new web3.eth.Contract((ERC20ABI as unknown) as AbiItem, address)
    return contract
  }

  const getERC20ContractWss = (address: string) => {
    const contract = new web3event.eth.Contract((ERC20ABI as unknown) as AbiItem, address)
    return contract
  }


  const getAllowance = async (addressCRP: string, tokenAddress: string): Promise<boolean> => {
    try {
      const tokenContract = getERC20ContractWss(tokenAddress)
      const allowance: string = await tokenContract.methods.allowance(userWalletAddress, addressCRP).call()
      return allowance !== "0"
    } catch (e) {
      return false
    }
  };
  
  const getBalance = async (tokenAddress: string, userAddress: string): Promise<BigNumber> => {
    const tokenContract = getERC20ContractWss(tokenAddress)
    try {
      const balance: string = await tokenContract.methods.balanceOf(userAddress).call()
      return new BigNumber(balance)
    } catch (e) {
      return new BigNumber(0)
    }
  };

  const getTotalSupply = async (tokenAddress: string): Promise<BigNumber> => {
    const tokenContract = getERC20ContractWss(tokenAddress)
    try {
      const supply: string = await tokenContract.methods.totalSupply().call()
      return new BigNumber(supply)
    } catch (e) {
      return new BigNumber(0)
    }
  };

  return { 
    getERC20Contract,
    getAllowance,
    getBalance,
    getTotalSupply,
    approve
  }
}

export default useERC20Contract