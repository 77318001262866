import React from 'react'
import useLocalStorage from '../LocalStorage/useLocalStorage'

import useERC20Contract from "./useERC20Contract"


const useBalance = () => {
  const [userWalletAddress, setUserWalletAddress] = useLocalStorage('userAddress', '')
  const { getBalance } = useERC20Contract()

  
  const getBalanceToken = React.useCallback( async (tokenSelected: string) => {
    const balance = await getBalance(tokenSelected, userWalletAddress)

    return balance
  }, [userWalletAddress])

  return {
    getBalanceToken
  }    

}

export default useBalance