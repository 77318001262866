import React from 'react'
import { ScrollTickets, ScrollContainer } from './styles';

export function MobileScroll() {

    return (
        <>
            <ScrollContainer>
                <h1>No mobile version!</h1>
            </ScrollContainer>
        </>
    )
}
