import React from 'react';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import detectEthereumProvider from '@metamask/detect-provider';
import BootstrapAlert, { AlertProps } from 'react-bootstrap/Alert';
import { useEffect, useState } from 'react';
import web3 from '../utils/web3'
import Web3Modal from "web3modal";
import useLocalStorage from '../LocalStorage/useLocalStorage'
import { actionGetUserAddressWallet } from '../store/modules/userWalletAddress/actions'
import toastImg from '../../assets/Toast.svg'
import X from '../../assets/X.svg'

import { Title, Text } from './styles'
import Logo3 from '../../assets/Logo3.png'


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { css } from 'styled-components';
import { url } from 'inspector';
import { Ticket } from '../constants/tokenAddresses';

declare let window: any;

export interface IMessages {
  head?: string;
  body?: any;
  variant?: string;
}

interface AddEthereumChainParameter {
  chainId: string;
  blockExplorerUrls?: string[];
  chainName?: string;
  iconUrls?: string[];
  nativeCurrency?: {
    name: string;
    symbol: string;
    decimals: number;
  };
  rpcUrls?: string[];
}
//utilizar o botão singIn na aplicação e repassar em conect para redux
const Alert = (props: AlertProps) => {
  return <BootstrapAlert bsPrefix="my-app-alert" {...props} />;
};
Alert.Heading = BootstrapAlert.Heading;
Alert.Link = BootstrapAlert.Link;

const useConnect = () => {
  const [currentAccount, setCurrentAccount] = useLocalStorage('userAddress', '')
  const [currentChainID, setCurrentChainID] = React.useState(-1)
  const [loading, setLoading] = useLocalStorage('loading', false)
  const [messages, setMessage] = React.useState<IMessages[]>([]);
  // const [isWalletPermissions, setIsWalletPermissions] = React.useState(false)

  const { userWalletAddress } = useSelector((state: RootStateOrAny) => state)
  const dispatch = useDispatch()

  const Message = (message: Array<IMessages>) => {

    const editMessage = (
      <div>
        <Title>{message[0].head}</Title>
        <Text>{message[0].body}</Text>
      </div>
    )
    switch(message[0].variant){
      case 'success':
          toast.success(editMessage, {
            style: {background: `url(${toastImg})`, width: '529.47px',
            height: '148.82px',},
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        break;
      case 'warning':
        toast.warn(editMessage, {
          style: {background: `url(${toastImg})`, width: '529.47px',
            height: '148.82px',},
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
        break;
      case 'info':
        toast.info(editMessage, {
          style: {background: `url(${toastImg})`, width: '529.47px',
            height: '148.82px',},
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
        break;
      default:
          setCurrentAccount('1')
        break;
    }
    
  }

  const SignIn = async () => {

    if(!web3.currentProvider) {

      const message = [{head : "Wallet not found", body: `Please install MetaMask!`, variant: 'warning'}]
      Message(message)

    } else {
      const address = await ConnectWallet()
      
      if (address)
        Message([{head : "User Login", body: `addres: ${String(address)}`, variant: 'success'}])
        setLoading(true)
    }
    

    
}

const AddCoin = async () => {
  const tokenAddress = Ticket;
const tokenSymbol = 'TICKET';
const tokenDecimals = 18;
const tokenImage = Logo3;

try {
  // wasAdded is a boolean. Like any RPC method, an error may be thrown.
  const wasAdded = await window.ethereum.request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20', // Initially only supports ERC20, but eventually more!
      options: {
        address: tokenAddress, // The address that the token is at.
        symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
        decimals: tokenDecimals, // The number of decimals in the token
        image: tokenImage, // A string url of the token logo
      },
    },
  });

  if (wasAdded) {
    Message([{head : "Success", body: `TICKET to metamaske`, variant: 'success'}]);
  } else {
    Message([{head : "Error", body: `TICKET not added to metamaske`, variant: 'success'}]);
  }
} catch (error) {
  console.log(error);
}
}

const VerifyChain = async () => {
  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: '0xFA' }], //0xFA
    });
  } catch (error) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (error.code === 4902) {
      try {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain', 
          params:[{ chainId: '0xFA', chainName: 'Fantom Opera', nativeCurrency: { name: 'Fantom', symbol: 'FTM', decimals: 18 }, rpcUrls: ['https://rpc.ftm.tools/'], blockExplorerUrls: ['https://ftmscan.com/'] }],
        }); //0xFA
      } catch (addError) {
        // handle "add" error
      }
    }
    // handle other "switch" errors
  }
}

const ConnectWallet = async () => {

  console.log("Try Connect");

  try {
    await window.ethereum.enable();

    const id = await window.ethereum.request({ method: 'eth_chainId' })
    VerifyChain()
    if(true){
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
      dispatch(actionGetUserAddressWallet(accounts[0]))
      setCurrentAccount(accounts[0])
      return accounts[0]  
    }
    if(loading){
      walletUpdateChain()
    }
    
    

  } catch(err) {
      if (err.code === 4001) {
        // EIP-1193 userRejectedRequest error
        // If this happens, the user rejected the connection request.
        console.log('Please connect to MetaMask.')
        const message = [{head : "User Rejected Request", body: 'Please connect to MetaMask.', variant: 'info'}]
        Message(message)

      } else if(err.code === -32002) {
        console.log('Please unlock MetaMask.')
        const message = [{head : "User Request Pending", body: 'Please unlock MetaMask and try agin.', variant: 'info'}]
        Message(message)
      } else {
        console.error(err);
        const message = [{head : "Error", body: err.message, variant: 'info'}]
        Message(message)
      }

  }
  
}

const handleDisconnected = React.useCallback((error) => {
  /* 
  Error From metamask always comes like this
  so the error object follows this pattern
  interface ProviderRpcError extends Error {
  message: string;
  code: number;
  data?: unknown;
}
Error codes
4001 ->The request was rejected by the user
-32602 ->The parameters were invalid
-32603 ->Internal error
  */
  const message = [{head : "User Disconnected", body: error.message, variant: 'info'}]
  Message(message)
}, [])

const walletUpdateChain = async() => { await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params:[{
          chainId: '0x13881',
          chainName: 'Fantom Opera',
          nativeCurrency: {
            name: 'Fantom',
            symbol: 'FTM',
            decimals: 18
          },
          rpcURL: 'https://rpc.ftm.tools/',
          blockExplorerUrl: 'https://polygonscan.com/'
          }
      ]})
    };

React.useEffect(() => {
  (async () => {
    const provider = await detectEthereumProvider()
    if (provider !== null) {
      const permissions = await window.ethereum.request({ method: 'wallet_getPermissions' });
      if (permissions.length > 0) {
        // User is already connected just straight log him in
        ConnectWallet()
        // setIsWalletPermissions(true)
      }
      else {
        // User not connected initial flow
        console.log("User has no permissions")
        // setIsWalletPermissions(false)
      }
    }else{
    setLoading(false)
    console.log("Install MestaMask")
  }
  if(loading){
    VerifyChain()
  }
})()
  

  
  // if (web3.currentProvider !== null){
  //   window.onbeforeunload = function() { return "Prevent reload" }
  //   window.ethereum.on('accountsChanged', handleAccountsChanged)
  //   // window.ethereum.on('disconnect',  handleDisconnected)
    
  //   window.ethereum.on('chainChanged', handleChainChanged)

  //   window.ethereum.on('chainChanged', (_chainId: string) => {
  //     console.log("chain number: "+ _chainId);
  //     console.log(typeof _chainId)
  //     console.log(_chainId === '0xFA')

  //     if(_chainId === '0xFA' || _chainId === '0x13881'){
  //       setCurrentChainID(() => parseInt(_chainId, 16))
  //     }
  //     else{
  //       new window.ethereum.request({
  //         method: 'wallet_addEthereumChain',
  //         params:[{
  //           chainId: '0x13881',
  //           chainName: 'Fantom Opera',
  //           nativeCurrency: {
  //             name: 'Fantom',
  //             symbol: 'FTM',
  //             decimals: 18
  //           },
  //           rpcURL: 'https://rpc-mainnet.matic.network',
  //           blockExplorerUrl: 'https://polygonscan.com/'
  //           }
  //       ]})
  //         SignOut()
  //         console.log('Please change to Mumbai or Matic Mainnet as we only accept those networks.')
  //         const message = [{head : "Invalid Network", body: 'Please change to Matic Mainnet', variant: 'warning'}]
  //     message}
  //     // window.location.reload()
  //   });
  // }else{
  //   console.log('install metamask')
  // }
}, [])



const handleChainChanged = React.useCallback((_chainId) => {
  // We recommend reloading the page, unless you must do otherwise
  // window.location.reload()
}, [])

const SignOut = async () => {
  setLoading(false)
  dispatch(actionGetUserAddressWallet(''))
  setCurrentAccount('')
  const message = [{head : "Logout", body: `To completely logout remove this site from metamask on connections`, variant: 'warning'}]
  Message(message)
}

  


const handleRequestAccounts = React.useCallback(() => {
  window.ethereum
    .request({ method: 'eth_accounts' })
    .then(handleAccountsChanged);

  // Note that this event is emitted on page load.
  // If the array of accounts is non-empty, you're already
  // connected.
  window.ethereum.on('accountsChanged', handleAccountsChanged);
}, [])



  // For now, 'eth_accounts' will continue to always return an array
  const handleAccountsChanged = React.useCallback((accounts) => {
    if (accounts.length === 0) {
      // MetaMask is locked or the user has not connected any accounts
      const message = [{head : "User Rejected Request", body: 'Please connect to MetaMask.', variant: 'info'}]
      Message(message)
      dispatch(actionGetUserAddressWallet(''))
    } else if (accounts[0] !== currentAccount) {
      setCurrentAccount(() => accounts[0])
      const message = [{head : "Account Changed", body: `addres: ${accounts[0]}`, variant: 'warning'}]
      Message(message)
      // Do any other work!
    }
  }, [])


  const connect = React.useCallback(() => {
    window.ethereum
      .request({ method: 'eth_requestAccounts' })
      .then(handleAccountsChanged)
      
  }, [])


  return {
    connect,
    handleRequestAccounts,
    SignOut,
    SignIn,
    Message,
    AddCoin,
    messages,
    loading,
    currentAccount
  }
}

export default useConnect