import { Container, FrameContent, Title, TalkLeft, AprText1, AprText2, CloseModalButton, Text1, Text2, StakeLpUnlocked, StakeLpLocked, Back, AddMetamask } from './styles'
import Frame from '../../../components/Frame';
import Navbar from '../../../components/Navbar';
import FrameBadge from '../../../components/FrameBadge';
import MarketInfo from '../../../components/MarketInfo';
import { useHistory } from 'react-router-dom';
import { Scroll } from '../../../components/Scroll';
import Modal from 'react-modal'
import React from 'react'
import useConnect from '../../../components/hooks/useConnect';
import useERC20Contract from '../../../components/hooks/useERC20Contract';
import useBalance from '../../../components/hooks/useBalance';
import usePriceLP from '../../../components/hooks/usePriceLP';
import useLockedLPStaking from '../../../components/hooks/useLockedLPStaking';
import { LockedLPStaking, MockLP, PriceLP, Ticket, LPStaking } from '../../../components/constants/tokenAddresses';
import { BNtoDecimal } from '../../../components/utils/numerals'
import BigNumber from 'bn.js'
import web3 from '../../../components/utils/web3'



export default function ConArtist() {
    const history = useHistory();
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [AprLocked, setAprLocked] = React.useState(0)
    const [Apr, setApr] = React.useState(0)
    const [counter, setCounter] = React.useState(0);


    const { AddCoin } = useConnect()

    const { approve, getAllowance, getBalance, getTotalSupply } = useERC20Contract()
    const { SignIn } = useConnect()
    const { getBalanceToken } = useBalance()
    const { viewgetReserves } = usePriceLP()


    const { stake, viewtotalSupply, viewbalanceOf, viewperiodFinish, viewearned, getReward } = useLockedLPStaking()


    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)'
          },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          background: 'rgba(0, 0, 0, 0.5)'
        },
      };

      function openModal() {
        setIsOpen(true);
      }

    function closeModal() {
        setIsOpen(false);
    }

    async function handlegetAPRLocked() {
        const balance = await viewgetReserves(PriceLP)
        const dayToEndinternal = await handleDaysEndLocked()
        const getBalancePollTicketinternal = await handlegetBalancePoolTicketLocked()
        const balancePool = await getBalance(MockLP, LockedLPStaking)
        const bigBalancePool = Number(BNtoDecimal(balancePool, new BigNumber(18), 10))
        const balanceTotalP = await getTotalSupply(PriceLP)
        const bigBalanceTotalLP = Number(BNtoDecimal(balanceTotalP, new BigNumber(18), 10))
        const dolar = new BigNumber(balance[1])
        const ticket = new BigNumber(balance[0])
        const bDolar = String(BNtoDecimal(dolar, new BigNumber(6), 10))
        const bTicket = String(BNtoDecimal(ticket, new BigNumber(18), 10))
        const priceTicketinternal = String(Number(bDolar)/Number(bTicket)).slice(0, -10)
        const totalLiquidRelativePool = Number(bigBalancePool/bigBalanceTotalLP * Number(bDolar) * 2)
        const apr = Number((((getBalancePollTicketinternal/dayToEndinternal) * Number(priceTicketinternal) * 365))  / Number(totalLiquidRelativePool) * 100)
        setAprLocked(Number(apr))
    }

    async function handleDaysEndLocked() {
        const balance = await viewperiodFinish(LockedLPStaking)
        const dateEnd = new Date(balance * 1000)
        const dateNow = new Date(Date.now())
        const oneDay = 1000* 60*60 * 24
        const diffInTime = dateEnd.getTime() - dateNow.getTime()
        const diffInDays = Math.round(diffInTime / oneDay)
        return(diffInDays)
    }

    async function handlegetBalancePoolTicketLocked() {
        const balance = await getBalance(Ticket, LockedLPStaking)
        const balanceNumber = Number(BNtoDecimal(balance, new BigNumber(18), 10))
        return(Number(balanceNumber))
    }

    async function handlegetAPR() {
        const balance = await viewgetReserves(PriceLP)
        const dayToEndinternal = await handleDaysEnd()
        const getBalancePollTicketinternal = await handlegetBalancePoolTicket()
        const balancePool = await getBalance(MockLP, LPStaking)
        const bigBalancePool = Number(BNtoDecimal(balancePool, new BigNumber(18), 10))
        const balanceTotalP = await getTotalSupply(PriceLP)
        const bigBalanceTotalLP = Number(BNtoDecimal(balanceTotalP, new BigNumber(18), 10))
        const dolar = new BigNumber(balance[1])
        const ticket = new BigNumber(balance[0])
        const bDolar = String(BNtoDecimal(dolar, new BigNumber(6), 10))
        const bTicket = String(BNtoDecimal(ticket, new BigNumber(18), 10))
        const priceTicketinternal = String(Number(bDolar)/Number(bTicket)).slice(0, -10)
        const totalLiquidRelativePool = Number(bigBalancePool/bigBalanceTotalLP * Number(bDolar) * 2)
        const apr = Number((((getBalancePollTicketinternal/dayToEndinternal) * Number(priceTicketinternal) * 365))  / Number(totalLiquidRelativePool) * 100)
        setApr(Number(apr))
    }

    async function handlegetBalancePoolTicket() {
        const balance = await getBalance(Ticket, LPStaking)
        const balanceNumber = Number(BNtoDecimal(balance, new BigNumber(18), 10))
        return(Number(balanceNumber))
    }

    async function handleDaysEnd() {
        const balance = await viewperiodFinish(LPStaking)
        const dateEnd = new Date(balance * 1000)
        const dateNow = new Date(Date.now())
        const oneDay = 1000* 60*60 * 24
        const diffInTime = dateEnd.getTime() - dateNow.getTime()
        const diffInDays = Math.round(diffInTime / oneDay)
        return(diffInDays)
    }

    React.useEffect(() => {
        (async () => {
            if(web3.currentProvider !== null){
            handlegetAPRLocked()
            handlegetAPR()
            }
        setTimeout(() => setCounter(counter+1), 1000)
        })()
        

    }, [counter])

    return (
        <Container>
            <Navbar />
            <Frame data='ConArtist'>
                <FrameContent>
                    <Scroll>
                        
                    </Scroll>
                    <div>
                        <Title> How degen are you? </Title>
                    </div>
                    <div>
                        <Text1>Just a bit degen:</Text1>
                    </div>
                    <div>
                        <Text2>Quite degen:</Text2>
                    </div>
                    
                    {/* <div >
                        <BuyTicket onClick={() => { 
                        window.open('https://quickswap.exchange/#/swap?outputCurrency=0x0514C869D3E0f553Edf590093914Abe91D9D0611', '_blank');}}></BuyTicket>
                    </div> */}
                    {/* <div>
                        <FarmTickets></FarmTickets>
                    </div> */}
                    <div>
                        <StakeLpUnlocked onClick={() => history.push('/conartist/stakelpunlocked')}></StakeLpUnlocked>
                    </div>
                    <div>
                        <StakeLpLocked onClick={() => history.push('/conartist/stacketicketusd')}>

                        </StakeLpLocked>
                    </div>
                    <div onClick={() => history.push('/conartist')}>
                        <Back>

                        </Back>
                    </div>
                    <div>
                        <TalkLeft onClick={openModal}></TalkLeft>
                    </div>
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        style={customStyles}
                    >
                        <div>
                            <h2 style={{color: '#FFFFFF'}}>You scratch my back, I’ll scratch yours... <br />
                                <br />
                                If you're willing to lend me some tickets and money, I can make it work for you... <br />
                                <br />
                                High yields with no risk at all, seriously! You can trust me...<br /></h2>
                            <h2 style={{marginTop: '15px', color: '#66605C', cursor: 'pointer'}} onClick={() => { 
                        window.open('https://medium.com/@louvre-finance/the-con-artist-4d01bff605e2', '_blank');}}>Learn more...</h2>
                            <CloseModalButton onClick={closeModal}>X</CloseModalButton>
                        </div>
                    </Modal>
                    <div>
                        <AprText1>APR: {Apr.toFixed(2)}%</AprText1>
                    </div>
                    <div>
                        <AprText2>APR: {AprLocked.toFixed(2)}%</AprText2>
                    </div>
                </FrameContent>
                <FrameBadge>
                    $TICKET Market
                </FrameBadge>

            </Frame>

            <MarketInfo />
        </Container>
    )
}