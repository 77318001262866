import { Container, FrameContent,} from './styles'
import Frame from '../../components/Frame';
import Navbar from '../../components/Navbar';
import FrameBadge from '../../components/FrameBadge';
import MarketInfo from '../../components/MarketInfo';
import OpenButton from './OpenButton';
import { useHistory } from 'react-router-dom';
import useLocalStorage from '../../components/LocalStorage/useLocalStorage';




export default function Suitcase() {
    const history = useHistory();

    const [currentAccount, setCurrentAccount] = useLocalStorage('userAddress', '')

    
    return (
        <Container> 
            <Navbar/>
            <Frame data='Suitcase'>
                <FrameContent>
                    <div onClick={() => {window.open( `https://opensea.io/${currentAccount}/louvre-finance`, '_blank');}}>
                    <OpenButton />
                    </div>
                </FrameContent>
                <FrameBadge>
                    Suitcase
                </FrameBadge>

            </Frame>

            <MarketInfo />
        </Container>
    )
}