import styled from 'styled-components';

export const Container = styled.div`
    width: 100vw !important;
    height: 100vh;

    .separator {
        height: 18px;
        max-width: 100vw;
    }
    
    display: flex;
    flex-direction: column;
    align-items: center;

    strong {
        color: ${({ theme }) => theme.colors.gold80};
        text-shadow: 0px 2px 0px #24103F;
        text-align: center;

        &:first-of-type {
            margin: 0 0.5rem;
        }

        cursor: pointer;
        font-size: 24px;
    }
`;

export const MobileNav = styled.nav`
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        margin-right: 12px;
    }
`;