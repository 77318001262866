import React from 'react'
import MarketStatsButton from './MarketStatsButton';
import useTSContrat from '../hooks/useTokenSales';
import BigNumber from 'bn.js'
import { BNtoDecimal } from '../../components/utils/numerals';
import { TokenSale } from '../../components/constants/tokenAddresses';
import web3 from '../../components/utils/web3'
import usePriceLP from '../hooks/usePriceLP';
import { LockedLPStaking, Louvre, PriceLP, Ticket, LPStaking } from '../constants/tokenAddresses';
import useLockedLPStaking from '../hooks/useLockedLPStaking';
import useERC20Contract from '../hooks/useERC20Contract'




import { Container } from './styles';



  export default function MarketInfo() {
      const [Price, setPrice] = React.useState('')
      const [priceLP, setPriceLP] = React.useState(0)
      const [allLiquidity, setAllLiquidity] = React.useState(0)
      const [totalLocked, setTotalLocked] = React.useState(0)
      const [circulationSupplyy, setCirculationSupplyy] = React.useState(0)

      const { viewtokenPrice, viewtokensSold } = useTSContrat()
      const { viewgetReserves } = usePriceLP()
      const { viewtotalSupply } = useLockedLPStaking()
      const { getBalance, getTotalSupply } = useERC20Contract()



    
      async function updatePrice() {
        const bigPrice = await viewtokenPrice(TokenSale)
        const price = BNtoDecimal(bigPrice, new BigNumber(6), 3)
        setPrice(price)
      }


    async function handlePrice() {
      const balance = await viewgetReserves(PriceLP)
      const dolar = balance[1] / 1000000
      const ticket = balance[0] / 1000000000000000000
      setPriceLP(dolar/ticket)
      setAllLiquidity(dolar*2)
  }
  
    async function handleLockLiquidity() {
      const balance = await viewgetReserves(PriceLP)
      const balanceLLP = await viewtotalSupply(LockedLPStaking)
      const balanceDead = await getBalance(PriceLP, '0x000000000000000000000000000000000000dEaD')
      const totalLP = await getTotalSupply(PriceLP)
      const dolar = balance[1] / 1000000
      const bTotalLP = Number(BNtoDecimal(totalLP, new BigNumber(18), 10))
      const bbalanceDead =  Number(BNtoDecimal(balanceDead, new BigNumber(18), 10))
      const bbalanceLLP = Number(BNtoDecimal(balanceLLP, new BigNumber(18), 10))
      const totalLocked = (((bbalanceDead+bbalanceLLP) / bTotalLP) * (dolar*2))
      setTotalLocked(totalLocked)
    }

    async function handleCirculationSupply() {
      const bigSold = await viewtokensSold(TokenSale)
      const balanceRewardLocked = await getBalance(Ticket, LockedLPStaking)
      const balanceReward = await getBalance(Ticket, LPStaking)
      const balanceLouvre = await getBalance(Ticket, Louvre)
      const circulationSupply = Number(bigSold) + (60000 - (Number(BNtoDecimal(balanceRewardLocked, new BigNumber(18), 18)) + Number(BNtoDecimal(balanceReward, new BigNumber(18), 18)))) + (40000 - Number(BNtoDecimal(balanceLouvre, new BigNumber(18), 18)))
      setCirculationSupplyy(circulationSupply)
    }



    React.useEffect(() => {
            if(web3.currentProvider !== null){
              handlePrice()
              updatePrice()
              handleLockLiquidity()
              handleCirculationSupply()
              setInterval(async() => {
                handlePrice()
                updatePrice()
                handleLockLiquidity()
                handleCirculationSupply()
              }, 30000)
            }
    }, [])

  return (
    <Container>
      <MarketStatsButton>
        <p>Total Supply: 200000</p>
        {/* <p>Circulating Supply: <br /> {circulationSupplyy.toFixed(2)}</p> */}
        <p>Circulating Supply: <br /> 0.00</p>
      </MarketStatsButton>
      <MarketStatsButton>
        {/* <p>Scam-o-matic price: <br /> <span> ${Number(Price).toFixed(2)}</span></p> */}
        {/* <p>Market price: <br /> <strong>${priceLP.toFixed(2)}</strong>  </p> */}
        <p>Market price: <br /> <strong>$0.00</strong>  </p>
      </MarketStatsButton>
      <MarketStatsButton>
        <p>Locked liquidity:</p>
        <p>$0.00</p>
        {/* <p>${totalLocked.toFixed(2)}</p> */}
        <p>All Liquidity:</p>
        <p>$0.00</p>
        {/* <p>${allLiquidity.toFixed(2)}</p> */}
      </MarketStatsButton>
    </Container>
  )
  }
