import styled from "styled-components";

export const MyButton = styled.button`
    font-family: 'Alagard';
    color: ${({ theme }) => theme.colors.gold100};
    background-color: ${({ theme }) => theme.colors.gold80}; 
    text-shadow: -1.5px -1.5px 0px ${({ theme }) => theme.colors.darkblue100};
    border: none;
    cursor: pointer;

    transition: ease-in-out 0.5s;

    filter: drop-shadow(4px 4px 0px ${({ theme }) => theme.colors.darkblue100});

    &:hover {
        background-color: ${({ theme }) => theme.colors.red20};
        box-shadow:
        0   -1px 0   0   ${({ theme }) => theme.colors.red20},
        0    1px 0   0   ${({ theme }) => theme.colors.red20},
        0  -4px 0  -4px  ${({ theme }) => theme.colors.red20},
        0   4px 0  -4px  ${({ theme }) => theme.colors.red20},
        0  -10px 0  -4px ${({ theme }) => theme.colors.red20},
        0   10px 0  -4px ${({ theme }) => theme.colors.red20},
        0  -18px 0  -8px ${({ theme }) => theme.colors.red20},
        0   18px 0  -8px ${({ theme }) => theme.colors.red20},
        0  -27px 0 -12px ${({ theme }) => theme.colors.red20},
        0   27px 0 -12px ${({ theme }) => theme.colors.red20};    
    }

    box-shadow:
      0   -1px 0   0   ${({ theme }) => theme.colors.shadow},
      0    1px 0   0   ${({ theme }) => theme.colors.shadow},
      0  -4px 0  -4px  ${({ theme }) => theme.colors.shadow},
      0   4px 0  -4px  ${({ theme }) => theme.colors.shadow},
      0  -10px 0  -4px ${({ theme }) => theme.colors.shadow},
      0   10px 0  -4px ${({ theme }) => theme.colors.shadow},
      0  -18px 0  -8px ${({ theme }) => theme.colors.shadow},
      0   18px 0  -8px ${({ theme }) => theme.colors.shadow},
      0  -27px 0 -12px ${({ theme }) => theme.colors.shadow},
      0   27px 0 -12px ${({ theme }) => theme.colors.shadow};
      
      
      .up-left {
            position: absolute;
            top: -13px;
            left: 15px;
        }

      .down-left {
            position: absolute;
            top: 33px;
            left: 15px;
      }
            
      .up-right {
            position: absolute;
            top: -13px;
            right: 15px;
        }

      .down-right {
            position: absolute;
            top: 33px;
            right: 15px;
      }
    
`;