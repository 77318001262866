import styled  from "styled-components";
import artworkScroll from '../../assets/Scroll/artworkScroll.svg';

export const ScrollContainer = styled.div`
   background: url(${artworkScroll}) no-repeat;
   position: absolute;
   width: 280px;
   height: 460px;
   left: calc(50% - 64px/2 + 224px);
   top: 295px;
   display: flex;
   flex-direction: column;
   z-index: 5;

   p {
      color: ${({ theme }) => theme.colors.red20};
      margin-left: 28px;
      width: 255px;
      font-size: 24px;
      padding-top: 48px;
      font-family: Fanda Egyptian;
      line-height: 1;
   }

   small {
      color: ${({ theme }) => theme.colors.black};
      font-size: 18px;
      margin-left: 30px;
      margin-right: 35px;
      font-family: Fanda Egyptian;
      line-height: 0.80;
      margin: 16px 0 5px 30px;
   }
   span {
      width: 230px;
      margin-left: 30px;

   }

   ul {
      margin-left: 30px;
      font-family: Fanda Egyptian;
      font-size: 18px;
      list-style: none;
            
      li {
         margin: 6px 0;
      }
   }
`;