import { ButtonContainer } from './styles';

export default function ArtworkButton(props: any) {
    return (
        <>
            <ButtonContainer>
                {props.children}
            </ButtonContainer>
        </>
    )
}