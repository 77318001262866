import { Container, FrameContent,  ArtworkContainer, ArtworkContent} from './styles'
import Frame from '../../../components/Frame';
import Navbar from '../../../components/Navbar';
import FrameBadge from '../../../components/FrameBadge';
import MarketInfo from '../../../components/MarketInfo';
import { useHistory } from 'react-router-dom';
import ArtworkSlider from './ArtworkSlider';




export default function Artworks() {
    const history = useHistory();
    
    return (
        <Container> 
            <Navbar/>
            <Frame data='OpenedSuitcase'>
                <ArtworkSlider/>
                <FrameBadge>
                    Suitcase
                </FrameBadge>
            </Frame>

            <MarketInfo />
        </Container>
    )
}