import { Container, FrameContent,Floors, TextOne, TitleOne, TextTwo, Back} from './styles'
import Frame from '../../components/Frame';
import Navbar from '../../components/Navbar';
import FrameBadge from '../../components/FrameBadge';
import MarketInfo from '../../components/MarketInfo';
import { useHistory } from 'react-router-dom';
import one from '../../assets/Louvre/1.svg';
import two from '../../assets/Louvre/2.svg';
import three from '../../assets/Louvre/3.svg';
import four from '../../assets/Louvre/4.svg';
import five from '../../assets/Louvre/5.svg';
import six from '../../assets/Louvre/6.svg';
import seven from '../../assets/Louvre/7.svg';
import { Scroll } from '../../components/Scroll';



export default function TheLouvre() {
    const history = useHistory();

    return (
        <Container> 
            <Navbar/>
            <Frame data='TheLouvre'>
                <FrameContent>
                    <Scroll>
                        
                    </Scroll>
                    {/* <div>
                        <TextOne>Sellect a</TextOne>
                    </div>
                    <div>
                        <TitleOne>Louvre Floor</TitleOne>
                    </div>
                    <div>
                        <TextTwo>The highest the floor, the less chance you have. <br />
                        <br />
                         But those are the most valuable artworks.</TextTwo>
                    </div>
                    <Floors>
                        <button onClick={() => history.push('/floor/7')}className="seven"><img src={seven} alt="seventh floor" /></button>
                        <button onClick={() => history.push('/floor/6')}className="six"><img src={six} alt="sixth floor" /></button>
                        <button onClick={() => history.push('/floor/5')}className="five"><img src={five} alt="fith floor" /></button>
                        <button onClick={() => history.push('/floor/4')}className="four"><img src={four} alt="fourth floor" /></button>
                        <button onClick={() => history.push('/floor/3')}className="three"><img src={three} alt="third floor" /></button>
                        <button onClick={() => history.push('/floor/2')}className="two"><img src={two} alt="second floor" /></button>
                        <button onClick={() => history.push('/floor/1')}className="one"><img src={one} alt="first floor" /></button>
                    </Floors> */}
                    <div><Back onClick={() => history.push('/play')}></Back></div>
                </FrameContent>
                <FrameBadge>
                    The Louvre
                </FrameBadge>

            </Frame>

            <MarketInfo />
        </Container>
    )
}