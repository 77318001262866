import { Container, FrameContent, TitleOne, TextOne, TalkLeft, CloseModalButton, ClaimApprove, Withdraw, TitleTwo, TextTwo, ConnectWallet, Back, AproveLp, CreatLp, DisableWithdraw, ClaimDisable, StakeLp } from './styles'
import Frame from '../../../components/Frame';
import Navbar from '../../../components/Navbar';
import FrameBadge from '../../../components/FrameBadge';
import MarketInfo from '../../../components/MarketInfo';
import { useHistory } from 'react-router-dom';
import { Scroll } from '../../../components/Scroll';
import useERC20Contract from '../../../components/hooks/useERC20Contract';
import useLPStaking from '../../../components/hooks/useLPStaking';
import usePriceLP from '../../../components/hooks/usePriceLP';
import React from 'react'
import { LPStaking, MockLP, PriceLP, Ticket } from '../../../components/constants/tokenAddresses';
import useBalance from '../../../components/hooks/useBalance'
import useLocalStorage from '../../../components/LocalStorage/useLocalStorage';
import BigNumber from 'bn.js'
import { BNtoDecimal } from '../../../components/utils/numerals'
import web3 from '../../../components/utils/web3'
import useConnect from '../../../components/hooks/useConnect';
import Modal from 'react-modal'



export default function ConArtistUnlockedStackeTicketUsdC() {
    const [isApproveLP, setisApproveLP] = React.useState(false)
    const [balanceToken, setBalanceToken] = React.useState<BigNumber>(new BigNumber(0))
    const [totalBalanceToken, setTotalBalanceToken] = React.useState<BigNumber>(new BigNumber(0))
    const [balanceOf, setBalanceOf] = React.useState<BigNumber>(new BigNumber(0))
    const [ErnedTiken, setViewEarnedTiken] = React.useState(0)
    const [getBalancePollTicket, setGetBalancePollTicket] = React.useState(0)
    const [priceTicket, setgetReserves] = React.useState(0)
    const [dayToEnd, setDayToEnd] = React.useState(0)
    const [apr, setApr] = React.useState(0)
    const [date, setDate] = React.useState('')
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [multiplicator, setMultiplicator] = React.useState(0)
    const [counter, setCounter] = React.useState(0);


    const history = useHistory();

    const [isLogged, setisLogged] = useLocalStorage('loading', false)
    const [userWalletAddress, setUserWalletAddress] = useLocalStorage('userAddress', '')



    const { approve, getAllowance, getBalance, getTotalSupply } = useERC20Contract()
    const { SignIn } = useConnect()
    const { getBalanceToken } = useBalance()
    const { viewgetReserves } = usePriceLP()


    const { stake, withdraw, viewtotalSupply, viewbalanceOf, viewperiodFinish, viewearned, getReward } = useLPStaking()

    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)'
          },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          background: 'rgba(0, 0, 0, 0.5)'
        },
      };

      function openModal() {
        setIsOpen(true);
      }

    function closeModal() {
        setIsOpen(false);
    }

    async function handleLPtoUSD() {
        const reserve = await viewgetReserves(PriceLP)
        const totalLP = await viewtotalSupply(PriceLP)
        const dolar = new BigNumber(reserve[1])
        const bDolar = Number(BNtoDecimal(dolar, new BigNumber(6), 10))
        setMultiplicator((1 /totalLP) * bDolar * 2)
    }

    async function handleApproveLP() {
        if (isApproveLP) {
          return true
        }else{
        const res = await approve(LPStaking, MockLP)
        setisApproveLP(res)
    }
    }

    async function handleBalance() {
        if (isLogged) {
        const balance = await getBalanceToken(MockLP)
        setBalanceToken(balance)
        } else {
            setBalanceToken(new BigNumber(0))
        }
    }

    async function handleviewearned() {

        const viewEarned = await viewearned(LPStaking)
        const reward = Number(BNtoDecimal(viewEarned, new BigNumber(18), 10))
        setViewEarnedTiken(reward)

    }

    async function handleBalanceOf() {
        if (isLogged) {
        const balance = await viewbalanceOf(LPStaking)
        setBalanceOf(balance)
        } else {
            setBalanceOf(new BigNumber(0))
        }
    }

    async function handleTotalBalanceToken() {
        const balance = await viewtotalSupply(LPStaking)
        setTotalBalanceToken(balance)
    }

    async function handlegetReserves() {
        const balance = await viewgetReserves(PriceLP)
        const dolar = balance[1] / 1000000
        const ticket = balance[0] / 1000000000000000000
        setgetReserves(dolar/ticket)
    }


    async function handlegetAPR() {
        const balance = await viewgetReserves(PriceLP)
        const dayToEndinternal = await handleDaysEnd()
        const getBalancePollTicketinternal = await handlegetBalancePoolTicket()
        const balancePool = await getBalance(MockLP, LPStaking)
        const bigBalancePool = Number(BNtoDecimal(balancePool, new BigNumber(18), 10))
        const balanceTotalP = await getTotalSupply(PriceLP)
        const bigBalanceTotalLP = Number(BNtoDecimal(balanceTotalP, new BigNumber(18), 10))
        const dolar = new BigNumber(balance[1])
        const ticket = new BigNumber(balance[0])
        const bDolar = String(BNtoDecimal(dolar, new BigNumber(6), 10))
        const bTicket = String(BNtoDecimal(ticket, new BigNumber(18), 10))
        const priceTicketinternal = String(Number(bDolar)/Number(bTicket)).slice(0, -10)
        const totalLiquidRelativePool = Number(bigBalancePool/bigBalanceTotalLP * Number(bDolar) * 2)
        const apr = Number((((getBalancePollTicketinternal/dayToEndinternal) * Number(priceTicketinternal) * 365))  / Number(totalLiquidRelativePool) * 100)
        setApr(Number(apr))
    }

    async function handlegetBalancePoolTicket() {
        const balance = await getBalance(Ticket, LPStaking)
        const balanceNumber = Number(BNtoDecimal(balance, new BigNumber(18), 10))
        setGetBalancePollTicket(Number(balanceNumber))
        return(Number(balanceNumber))
    }

    async function handleperiodFinesh() {
        const balance = await viewperiodFinish(LPStaking)
        const date = new Date(balance * 1000)
        const Day = date.getDay()
        const Month = date.getMonth()
        const Year = date.getFullYear()
        setDate(`${Month}-${Day}-${Year}`)
    }

    async function handleDaysEnd() {
        const balance = await viewperiodFinish(LPStaking)
        const dateEnd = new Date(balance * 1000)
        const dateNow = new Date(Date.now())
        const oneDay = 1000* 60*60 * 24
        const diffInTime = dateEnd.getTime() - dateNow.getTime()
        const diffInDays = Math.round(diffInTime / oneDay)
        setDayToEnd(diffInDays)
        return(diffInDays)
    }

    React.useEffect(() => {
        (async () => {
            if(web3.currentProvider !== null){
            handleLPtoUSD()
            handleperiodFinesh()
            handlegetBalancePoolTicket()
            handlegetReserves()
            handleDaysEnd()
            handlegetAPR()
            handleBalanceOf()
            handleTotalBalanceToken()
            if(isLogged){
                getAllowance(LPStaking, MockLP)
                    .then((response: boolean) => setisApproveLP(response))
                handleLPtoUSD()
                handleviewearned()
                handleBalanceOf()
                handleBalance()
            }else{
                handleLPtoUSD()
                setViewEarnedTiken(0)
                setBalanceOf(new BigNumber(0))
                setBalanceToken(new BigNumber(0))
            }    
        }
        setTimeout(() => setCounter(counter+1), 1000)
    })()
    }, [counter, isLogged])


    return (
        <Container>
            <Navbar />
            <Frame data='ConArtist2'>
                <FrameContent>
                    <Scroll>
                        <p>The Plot</p>
                    </Scroll>
                    <div>
                        <TitleOne> TICKETS-USDC LP <br />
                            <br />
                        </TitleOne>
                    </div>
                    <div>
                        <TextOne>
                            Withdraw fee: <span>5%</span> <br />
                            Total staked: <span> ${(Number(BNtoDecimal(totalBalanceToken, new BigNumber(0), 0))*multiplicator).toFixed(2)}  USDC</span> <br />
                            Ticket Price: <span>${priceTicket.toFixed(2)} USDC</span> <br />
                            Daily reward: <span>{Math.floor(getBalancePollTicket/dayToEnd).toFixed(3)} TICKETS</span> <br />
                            APR: <span>{apr.toFixed(3)} %</span> <br />
                            End-Period: <span>{date}</span> 
                        </TextOne>
                    </div>

                    <div>
                        <TitleTwo>
                            Your LP books: 
                        </TitleTwo>
                    </div>
                    <div>
                        <TextTwo><br />
                            Balance of TICKETS-USDC LP: <span>${isLogged ? (Number(BNtoDecimal(balanceToken, new BigNumber(0), 0))* multiplicator).toFixed(2) : "0.00"} </span> <br />
                            Staked TICKETS-USDC LP: <span>${isLogged ? (Number(BNtoDecimal(balanceOf, new BigNumber(0), 0))* multiplicator).toFixed(2) : "0.00"} </span> <br />
                            Pending reward: <span>{isLogged ? ErnedTiken.toFixed(6) : "0.000000"} TICKETs</span> </TextTwo>
                    </div>
                    {isLogged === true ?
                        isApproveLP === true ?
                        <div>
                            <StakeLp onClick={() => stake(LPStaking, balanceToken)}></StakeLp>
                        </div>
                        :
                        <div>
                        <AproveLp onClick={handleApproveLP}></AproveLp>
                        </div>
                        :
                        <div>
                            <ConnectWallet onClick={() => SignIn()}></ConnectWallet>
                        </div>
                    }
                    <div onClick={() => { 
                        window.location.href = 'https://quickswap.exchange/#/add/0x0514C869D3E0f553Edf590093914Abe91D9D0611/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174';}}>
                        <CreatLp></CreatLp>
                    </div>
                    {Number(BNtoDecimal(balanceOf, new BigNumber(18), 10)) > 0 && isLogged ?
                    <div onClick={() => withdraw(LPStaking, balanceOf)}>
                        <Withdraw></Withdraw>
                    </div>
                    :
                    <div>
                        <DisableWithdraw></DisableWithdraw>
                    </div>
                    }
                    {ErnedTiken > 0 && isLogged ? 
                    <div >
                        <ClaimApprove onClick={() => getReward(LPStaking)}></ClaimApprove>
                    </div>
                    :
                    <div>
                        <ClaimDisable>

                        </ClaimDisable>
                    </div>
                    }
                    
                    <div>
                        <Back onClick={() => history.push('/conartist/choicefarm')}></Back>
                    </div>
                    <div>
                        <TalkLeft onClick={openModal}></TalkLeft>
                    </div>
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        style={customStyles}
                    >
                        <div>
                            <h2 style={{color: '#FFFFFF'}}>Easy money... <br />

                            Go to QuickSwap, stake your tickets with some money, and lend it to me... <br />

                            If you do so, I'll be giving you tickets for free... <br />

                            You're free to withdraw your money at anytime you want, but there will be a 5% fee...</h2>
                            <h2 style={{marginTop: '15px', color: '#66605C', cursor: 'pointer'}} onClick={() => { 
                        window.open('https://medium.com/@louvre-finance/the-con-artist-4d01bff605e2', '_blank');}}>Learn more...</h2>
                            <CloseModalButton onClick={closeModal}>X</CloseModalButton>
                        </div>
                    </Modal>
                </FrameContent>
                <FrameBadge>
                    Con Artist
                </FrameBadge>

            </Frame>

            <MarketInfo />
        </Container>
    )
}