import { ButtonGetTicket, Container, FrameContent, Back, ButtonApprove, SmallFrame, ButtonBuyUsdc, SmallTag, TextOne, TextTree, TextTwo, ButtonConnectScam, Talk, CloseModalButton } from './styles'
import Frame from '../../components/Frame';
import Navbar from '../../components/Navbar';
import FrameBadge from '../../components/FrameBadge';
import MarketInfo from '../../components/MarketInfo';
import { useHistory } from 'react-router-dom';
import { Scroll } from '../../components/Scroll';
import useTSContrat from '../../components/hooks/useTokenSales';
import { TokenSale, USDC, Ticket } from '../../components/constants/tokenAddresses';
import useERC20Contract from '../../components/hooks/useERC20Contract';
import { BNtoDecimal } from '../../components/utils/numerals';
import BigNumber from 'bn.js'
import React from 'react'
import useLocalStorage from '../../components/LocalStorage/useLocalStorage';
import useConnect from '../../components/hooks/useConnect';
import web3 from '../../components/utils/web3'
import Modal from 'react-modal'
import useBalance from '../../components/hooks/useBalance';
import { time } from 'console';

export default function ScamOMatic() {
    const history = useHistory();
    const [isApproveCRP, setIsApproveCRP] = React.useState(false)
    const [userWalletAddress, setUserWalletAddress] = useLocalStorage('userAddress', '')
    const [isApproveUSDC, setisApproveUSDC] = React.useState(false)
    const [Price, setPrice] = React.useState('')
    const [Time, setTime] = React.useState(Number)
    const [Sold, setSold] = React.useState(Number)
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [isUsdc, setisUsdc] = React.useState(false);
    const [counter, setCounter] = React.useState(0);


    const [isLogged, setisLogged] = useLocalStorage('loading', false)

    const forceUpdate: () => void = React.useState(Array)[1].bind(null, [])


    const { buyToken, viewlastSale, viewtokenPrice, viewtokensSold } = useTSContrat()
    const { approve, getAllowance } = useERC20Contract()
    const { SignIn } = useConnect()
    const { getBalanceToken } = useBalance()

    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)'
          },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          background: 'rgba(0, 0, 0, 0.5)'
        },
      };

    function openModal() {
        setIsOpen(true);
      }

    function closeModal() {
        setIsOpen(false);
    }

    async function handleApproveUSDC() {
        if (isApproveUSDC) {
          return true
        }else{
        const res = await approve(TokenSale, USDC)
        setisApproveUSDC(res)
    }
}
    async function checkUsdc() {
        const balanceUsdc = await getBalanceToken(USDC)
        const bUsdc = Number(BNtoDecimal(balanceUsdc, new BigNumber(6), 2))
        if (bUsdc >= Number(Price)){
            setisUsdc(true)
        } else {
            setisUsdc(false)
        }
    }

    async function updatePrice() {
        const bigPrice = await viewtokenPrice(TokenSale)
        const price = BNtoDecimal(bigPrice, new BigNumber(6), 6)
        setPrice(price)
      }

    async function updateTime() {
        const bigTime = await viewlastSale(TokenSale)
        const time = bigTime - Math.floor(Date.now()/1000)
        setTime(time)
    }

    async function updateStock() {
        const bigSold = await viewtokensSold(TokenSale)
        const Sold = 100000 - bigSold
        setSold(Sold)
    }

    React.useEffect(() => {
        (async () => {
            forceUpdate()
            if(web3.currentProvider !== null){
                if(isLogged){
                getAllowance(TokenSale, USDC)
                    .then((response: boolean) => setisApproveUSDC(response))    
                }        
            checkUsdc()
            updatePrice()
            updateTime()
            updateStock()
            setTimeout(() => setCounter(counter+1), 1000)
        }})()
    }, [counter, isLogged])
    return (
        <Container>

            <Navbar />
            <Frame data='ScamOMatic'>
                <FrameContent>
                    <Scroll>
                        
                    </Scroll>
                    <div>
                        <SmallTag></SmallTag>
                    </div>
                    <div>
                        <TextOne>${Number(Price).toFixed(2)}</TextOne>
                    </div>
                    {isLogged === true ?
                            isUsdc === true ?
                                isApproveUSDC === true ?
                                <div>
                                    <ButtonGetTicket onClick={() => buyToken(TokenSale)}></ButtonGetTicket>
                                </div>
                                :
                                <div>
                                    <ButtonApprove onClick={handleApproveUSDC}></ButtonApprove>
                                </div>
                            :
                            <div>
                                <ButtonBuyUsdc onClick={() => {
                                    window.open( 'https://quickswap.exchange/#/swap?outputCurrency=0x2791bca1f2de4661ed88a30c99a7a9449aa84174', '_blank');}}></ButtonBuyUsdc>
                            </div>
                    :
                    <div>
                        <ButtonConnectScam onClick={() => {SignIn()}}></ButtonConnectScam>
                    </div>
                    }
                    <div>
                        <SmallFrame></SmallFrame>
                    </div>
                    <div>
                        {(Time + 30) >= 0?
                        <TextTwo>{(Time + 30)} s</TextTwo>
                        :
                        <TextTwo style={{"color": "#548f6f"}}>ready</TextTwo>
                    }
                    </div>
                    <div>
                        <TextTree>STOCK: {(Sold)}</TextTree>
                    </div>
                    <div>
                        <Talk onClick={openModal}></Talk>
                    </div>
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        style={customStyles}
                    >
                        <div>
                            <h2 style={{color: '#FFFFFF'}}>It looks like an old machine with cheap Louvre tickets, but this damn thing never works... <br />
                            <br />
                            In theory, if the stock is not zero and the small timer says "ready" the fastest person can buy a single ticket, <br /> and then the timer reset itself... <br />
                            <br />
                            At least it returns your money if someone goes before you and the ticket doesn't come out... <br />
                            <br />
                            </h2>
                            <h2 style={{marginTop: '15px', color: '#66605C', cursor: 'pointer'}} onClick={() => { 
                        window.open('https://medium.com/@louvre-finance/the-scam-o-matic-9ea33b9e5d6a', '_blank');}}>Learn more...</h2>
                            <CloseModalButton onClick={closeModal}>X</CloseModalButton>
                        </div>
                    </Modal>
                    <div>
                        <Back onClick={() => history.push('/play')}></Back>
                    </div>
            
                </FrameContent>
                <FrameBadge>
                    Scam-o-Matic
                </FrameBadge>

            </Frame>

            <MarketInfo />
        </Container>
    )
}