import { Container, ExitOrange, FrameContent, NextPage, PrevewsPage, TextOne, TextTwo, TitleOne, TitleTwo, BackGreenButton } from './styles'
import Button from '../../../components/Button';
import Frame from '../../../components/Frame';
import Navbar from '../../../components/Navbar';
import FrameBadge from '../../../components/FrameBadge';
import MarketInfo from '../../../components/MarketInfo';
import BlueButton from '../ButtonNotebook/Blue'
import { useHistory } from 'react-router-dom';
import RedButton from '../ButtonNotebook/Red';
import OrangeButton from '../ButtonNotebook/Orange';




export default function Notebook() {

    const history = useHistory();

    
    return (
        <Container> 
            <Navbar/>
            <Frame data='Notebook2'>
            
                <FrameContent >
                    <div>
                    <TitleOne>Con Artist</TitleOne>
                    </div>
                    <div>
                    <TextOne>After some talks and some coins I found out about a a girl that stays just outside the city and deals every kind of museum tickets. <br />
                    <br /> I can't go to the museum and buy a TICKET, they will have all my information registered, not ideal, no...
                    </TextOne>
                    </div>

                    <div>
                        <TitleTwo>
                        </TitleTwo>
                    </div>
                    <div>
                        <TextTwo>
                        
                        </TextTwo>
                    </div>
                    <div onClick={() => history.push('/notebook/3')}>
                        <BlueButton></BlueButton>
                    </div>
                    <div onClick={() => history.push('/notebook/4')}>
                        <RedButton></RedButton>
                    </div>
                    <div>
                        <ExitOrange></ExitOrange>
                    </div>
                    <div onClick={() => history.push('/notebook/3')}>
                        <NextPage>next page -{'>'}</NextPage>
                    </div>
                    <div onClick={() => history.push('/notebook/1')}>
                        <PrevewsPage>{'<'}- previus page</PrevewsPage>
                    </div>
                    <div onClick={() => history.push('/notebook/1')}>
                        <BackGreenButton></BackGreenButton>
                    </div>
                </FrameContent>
                <FrameBadge>
                    Plans for the Heist
                </FrameBadge>

            </Frame>

            <MarketInfo />
        </Container>
    )
}