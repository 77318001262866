import { Background } from './styles';

export default function MarketStatsButton(props: any) {
    return (
        <>
            <Background>
                {props.children}
            </Background>
        </>
    )
}