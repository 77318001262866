import { FrameContent, ArtworkContainer, ElementContainer, Back, Back1 } from './styles'
import { Container } from "./Robbed/styles";
import CrossedPoliceLines from "../../../../../assets/Louvre/CrossedPoliceLines.svg"
import OnePoliceLine from "../../../../../assets/Louvre/OnePoliceLine.svg"

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import React from 'react'
// swiper bundle styles
import 'swiper/swiper-bundle.min.css'
// swiper core styles
import 'swiper/swiper.min.css'
// modules styles
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'
import { ArtworkScroll } from '../../../../../components/ArtworkScroll';
import ArtworkButton from './Artworks/ArtworkButton';
import { FloorContext } from '../../../../../context/FloorProvider';
import RobbedArtwork from './Robbed';
import LoadingArtwork from './Loading';
import useLouvre from '../../../../../components/hooks/useLouvre';
import {LART, Louvre, PriceLP} from '../../../../../components/constants/tokenAddresses';
import web3 from '../../../../../components/utils/web3'
import { BNtoDecimal } from '../../../../../components/utils/numerals';
import BigNumber from 'bn.js'
import usePriceLP from '../../../../../components/hooks/usePriceLP';
import { useHistory } from 'react-router-dom';
import useLocalStorage from '../../../../../components/LocalStorage/useLocalStorage'
import {Link} from 'react-router-dom';
import useConnect from '../../../../../components/hooks/useConnect';



export interface IAttempt {
    id: number;
    attempt: number
  }

SwiperCore.use([Navigation, Pagination]);



export default function FloorSlider(props: any) {
    const [art, setArt] = useLocalStorage('useArt', 0)
    const [timeMachine, setTimeMachine] = useLocalStorage('timeMachine', 0)
    const [hired, setHired] = useLocalStorage('hired', 0)
    const [concludMachine, setConcludMachine] = useLocalStorage('concludMachine', false)
    const [isLogged, setisLogged] = useLocalStorage('loading', false)



    const history = useHistory();

    const { floor, getFloor } = React.useContext(FloorContext);
    const [ flore, setFlore ]  = React.useState(new Array)
    const [ swiper1, setSwiper1 ]  = React.useState<any>([])
    const [ reserve, setReserves ]  = React.useState(0)
    const [ isLoading, setLoading ]  = React.useState(Boolean(false))
    const forceUpdate: () => void = React.useState(Array)[1].bind(null, [])



    const {viewNumberOfAttempts, viewNumberOfSucesses, viewProbability, viewReward, viewNFTStatus} = useLouvre()
    const { viewgetReserves } = usePriceLP()
    const { SignIn } = useConnect()

    function sleep(delay: number) {
        var start = new Date().getTime();
        while (new Date().getTime() < start + delay);
    }
    
    async function updateNumberofAttempts(id: number) {
        const attempts = await viewNumberOfAttempts(Louvre, id)
        return attempts
    }

    async function updateNumberOfRobbed(id: number) {
        const attempts = await viewNumberOfSucesses(Louvre, id)
        return attempts
    }

    async function updateNumberOfProbability(id: number, hired: number) {
        const attempts = await viewProbability(Louvre, id, hired)
        return attempts
    }

    async function updateNumberOfReward(id: number) {
        const attempts = await viewReward(Louvre, id)
        return attempts
    }

    async function updateRobbed(id: number) {
        const attempts = await viewNFTStatus(Louvre, id)
        return attempts
    }

    async function handlegetReserves() {
        const balance = await viewgetReserves(PriceLP)
        const dolar = balance[1] / 1000000
        const ticket = balance[0] / 1000000000000000000
        setReserves(dolar/ticket)
    }


    function updateFloor(attempts: any[], robbedTimes: any[], probability: any[], reward: any[], robbed: any[]) {
        const Floor = new Array()
        getFloor(props.data)
        floor.Artworks.map(artwork => {
            Floor.push(artwork)            
        })
       

        const mergeById = (a1: any[], a2: any[]) =>
            a1.map(itm => ({
                ...a2.find((item) => (item.id === itm.id) && item),
                ...itm
            }));

        
        const Floor1 = mergeById(mergeById(mergeById(mergeById(Floor, attempts), robbedTimes), probability), reward)
        const Floor2 = mergeById(robbed, Floor1)

        setFlore(Floor2)
    }

    React.useEffect(() => {
        (async () => {
        forceUpdate()
        getFloor(1)
        getFloor(props.data)
        handlegetReserves()
        var attempts: any[] = []
        var RobbedTimes: any[] = []
        var probability: any[] = []
        var reward: any[] = []
        var robbed: any[] = []
        await Promise.all(
            floor.Artworks.map(async artwork => {
                    if(web3.currentProvider !== null){
                        const ida = artwork.id
                        const numberAttempst = await Promise.resolve(updateNumberofAttempts(artwork.id))
                        attempts.push({id: ida, attempt: Number(numberAttempst)})
                        const numberRobbed = await Promise.resolve(updateNumberOfRobbed(artwork.id))
                        RobbedTimes.push({id: ida, robbedTimes: Number(numberRobbed)})
                        const numberProbability = await Promise.resolve(updateNumberOfProbability(artwork.id, (hired+1)))
                        probability.push({id: ida, probability: Number(numberProbability/10000)})
                        const numberReward = await Promise.resolve(updateNumberOfReward(artwork.id))
                        const bigNumberReward = BNtoDecimal(numberReward, new BigNumber(18), 2)
                        reward.push({id: ida, reward: Number(bigNumberReward)})
                        const robbeded = await Promise.resolve(updateRobbed(artwork.id))
                        robbed.push({id: ida, Robbed: !robbeded})
                }
            })
        )
        updateFloor(attempts, RobbedTimes, probability, reward, robbed)
    })()
    // flore[0].Floor === props.data ? setLoading(false) : setLoading(true)
    },[props, swiper1]);

    const Style = {
        "backgroundColor": `${floor.Color}`,
    };

    return (

        <FrameContent>
            {flore.length === floor.Artworks.length ? 
            <Swiper
                style={Style}
                id="main"
                navigation={{
                    prevEl: '.prev',
                    nextEl: '.next',
                }}
                pagination={{ clickable: true, type: 'custom' }}
                slidesPerView={1}
                onSwiper={swiper => setSwiper1(swiper)}
                onSlideChange={() => console.log()}
                preventClicks={false}
                preventClicksPropagation={false}
                slideToClickedSlide={false}
                speed={600}          
            >
                {flore.map(artwork => (
                    <SwiperSlide key={artwork.id}>
                        <ArtworkContainer>
                            <img typeof="arte" src={require(`../../../../../assets/Artworks/${artwork.Floor}/${artwork.Title.replace(/ /g, '')}.png`).default} alt={artwork.Title} />
                            {
                            artwork.Element === "" ? 
                                artwork.Robbed === false?
                                    <div></div>
                                    :
                                    <RobbedArtwork/>
                                :
                                artwork.Robbed === false?
                                    <ElementContainer>
                                    <img src={require(`../../../../../assets/Louvre/${artwork.Floor}/${artwork.Element}.png`).default} alt={artwork.Element} />
                                    </ElementContainer>
                                    :
                                    <div>
                                        <RobbedArtwork/>
                                        <ElementContainer>
                                        <img src={require(`../../../../../assets/Louvre/${artwork.Floor}/${artwork.Element}.png`).default} alt={artwork.Element} />
                                        </ElementContainer>
                                    </div>
                            }
                            
                        </ArtworkContainer>
                        <ArtworkScroll>
                            <p>{artwork.Title}</p>
                            <span>{artwork.Description}</span>
                            <small>Attempts: {artwork.attempt}</small>
                            <small>Stolen: {artwork.robbedTimes} times</small>
                            <small>Success rate: {(artwork.probability)}%</small>
                            <small>Reward: {(artwork.reward).toFixed(2)} TICKET</small>
                            {isLogged === true ? artwork.Robbed === false ? <Link onClick={() => {setArt(artwork.id); setTimeMachine(0); setConcludMachine(false)}} to={`/thelouvre/steal/${artwork.id}`}><ArtworkButton>Steal</ArtworkButton></Link> : <Link onClick={() => {setArt(artwork.id); setTimeMachine(0); setConcludMachine(false)}} to={`/thelouvre/steal/${artwork.id}`} ><ArtworkButton >Return</ArtworkButton></Link> : <Link onClick={() => {SignIn()}} to={`/floor/${artwork.Floor}`}><ArtworkButton>Connect Wallet</ArtworkButton></Link>}
                            <Link onClick={() => {window.open( `https://opensea.io/assets/matic/${LART}/${artwork.id}`, '_blank')}} to={`?`}><ArtworkButton>View on OS</ArtworkButton></Link>
                        </ArtworkScroll>
                        
                    </SwiperSlide>
                ))}

            </Swiper>

            : 
                <LoadingArtwork />
            }
            {flore.length === floor.Artworks.length ?
            <div>
            <div className="prev"><button className="swiper-button-prev"></button></div>
            <div className="next"><button className="swiper-button-next"></button></div>
            </div>
            : 
            <div></div>
        }
            
        </FrameContent>
    )
}