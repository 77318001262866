import ButtonApp from "../Button";
import { Container, LinkInstallMetaMask } from "./styles";
import logo from '../../assets/logo.svg'
import navSeparator from '../../assets/navSeparator.svg';
import { useSelector, RootStateOrAny, Provider } from 'react-redux'
import {Connecteded} from './styles'
import web3 from '../utils/web3'



import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useHistory } from 'react-router-dom';


import useConnect, { IMessages } from '../hooks/useConnect'
import substr from '../utils/substr'
import { Modal } from "react-bootstrap";

declare let window: any;


export default function Navbar()  {
    const { userWalletAddress } = useSelector((state: RootStateOrAny) => state)
    const { SignIn, SignOut, messages, Message, loading, currentAccount } = useConnect()

    const history = useHistory();

    const Style = {
        "cursor": "pointer",
    }
    const css = `
    .message-list {
        position: absolute;
        display: flexbox;
        right: 1%;
        top: 70px;
      }
      
      .connect-button {
        min-width: 120px;
    }`

    return (
        <>
            <Container>
                <div style={Style} onClick={() => history.push('/')}>
                    <img src={logo} alt="Louvre Finance" />
                    <strong><p>Louvre</p> Finance</strong>
                </div>
                <div>
                    <ButtonApp onClick={() => history.push('/play')}>Home</ButtonApp>
                    <ButtonApp>Buy</ButtonApp>
                    <ButtonApp onClick={() => history.push('/thelouvre')}>Steal</ButtonApp>
                    <ButtonApp onClick={() => history.push('/shh')}>Shh...</ButtonApp>
                </div>
                <div style={Style}>
                {web3.currentProvider !== null ? 
                loading ?

                <div>
                    
                <Connecteded onClick={() => {SignOut()}} style={{visibility: loading ? "visible" : "hidden"}}><br /><strong>{substr(currentAccount)}</strong></Connecteded>
                <br></br>
                
                </div>

                    :

                    <strong onClick={() => {SignIn()}}>{'Connect Wallet'}</strong>
                :
                <div onClick={() => { 
                    window.location.href = 'https://metamask.io/download.html';}}>
                <strong>
                    Install MetaMask!
                </strong>
                </div>
        }
                </div>
            </Container>
            <ToastContainer></ToastContainer>
            
            <img width="100%" src={navSeparator} alt="Nav separator" />

        </>
    )
}