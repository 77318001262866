import styled  from "styled-components";
import artworksScroll from '../../assets/Scroll/artworksScroll.svg';

export const ScrollContainer = styled.div`
   background: url(${artworksScroll}) no-repeat;
   position: relative;
   width: 280px;
   height: 598px;
   top: -510px;
   left: 664px;
   display: flex;
   flex-direction: column;

   p {
      color: ${({ theme }) => theme.colors.red20};
      margin-left: 28px;
      font-size: 18px;
      font-family: Fanda Egyptian;
      max-width: 180px;
   }

   small {
      color: ${({ theme }) => theme.colors.black};
      font-size: 18px;
      margin-left: 30px;
      font-family: Fanda Egyptian;
      line-height: 0.1;
   }

   ul {
      margin-left: 30px;
      font-family: Fanda Egyptian;
      font-size: 18px;
      list-style: none;
   }
`;