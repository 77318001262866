import { useHistory } from 'react-router-dom'
import { ArtworksScroll } from '../../../components/ArtworksScroll'
import Frame from '../../../components/Frame'
import FrameBadge from '../../../components/FrameBadge'
import MarketInfo from '../../../components/MarketInfo'
import Navbar from '../../../components/Navbar'
import { Scroll } from '../../../components/Scroll'
import CheckButton from './CheckButton'
import { Container, FrameContent} from './styles'
import { ArtworkContext } from '../../../context/ArtworkProvider';
import { useContext } from 'react';




export default function OpenedSuitcase() {
    const history = useHistory();
    const { artworks } = useContext(ArtworkContext);
    
    return (
        <Container> 
            <Navbar/>
            <Frame data='OpenedSuitcase'>
                <FrameContent>
                    <Scroll>
                        <p>The Plot</p>
                    </Scroll>

                    <ArtworksScroll>
                        <p style={{ marginTop: '60px'}}>Robbed:</p>
                        <small>{artworks.length} Artworks</small>
                        <ul>
                            <li>{artworks[0].Title}</li>
                            <li>{artworks[1].Title}</li>
                            <li>{artworks[2].Title}</li>
                            <li>{artworks[3].Title}</li>
                        </ul>
                        <div onClick={() => history.push('/suitcase/artworks')}>
                            <CheckButton>Check</CheckButton>
                        </div>
                    </ArtworksScroll>

                </FrameContent>
                <FrameBadge>
                    Suitcase
                </FrameBadge>

            </Frame>

            <MarketInfo />
        </Container>
    )
}