import styled, { css } from "styled-components";
import openedScroll from '../../assets/Scroll/openedScroll.svg';
import closedScroll from '../../assets/Scroll/closedScroll.svg';

export interface IScrollProps {
   isOpen: boolean;
   onClick?: () => void;
   children: React.ReactNode
} 

export const ScrollContainer = styled.div<IScrollProps>`
   background: url(${closedScroll}) no-repeat;
   position: absolute;
   width: 261px;
   height: 99px;
   top: 159px;
   left: calc(50% - 300px/2 - 317px);
   display: flex;
   cursor: pointer;
   z-index: 100;

   ${({ isOpen }) =>
      isOpen &&
      css`
         background: url(${openedScroll}) no-repeat;
         height: 208px;
         z-index: 101;
    `}

   p {
      color: ${({ theme }) => theme.colors.red20};
      margin-left: 28px;
      font-size: 24px;
      font-family: Fanda Egyptian;
      line-height: 0.1;
      margin-top: 20px;
      z-index: 101;
   }

   small {
      color: ${({ theme }) => theme.colors.gold100};
      font-size: 18px;
      margin-left: 70px;
      font-family: Fanda Egyptian;
      line-height: 0.1;
      margin-top: 20px;
      z-index: 102;
   }
`;

export const ScrollTickets = styled.div`
   position: absolute;
   top: 210px;
   left: calc(50% - 220px/2 - 317px);
   font-size: 18px;
   font-family: Fanda Egyptian;
   color: ${({ theme }) => theme.colors.black};
   z-index: 103;
`