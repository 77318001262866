import { Container } from "./styles";
import CrossedPoliceLines from "../../../../assets/Louvre/CrossedPoliceLines.svg"
import OnePoliceLine from "../../../../assets/Louvre/OnePoliceLine.svg"



export default function RobbedArtwork() {
    return(
        <>
            <Container>
                <div>
                    <img src={CrossedPoliceLines} alt="Do not Cross, we are investigating" />
                </div>
                <div>
                    <h1>Stolen</h1>
                    <h2>Artwork</h2>
                </div>
                <div>
                    <img src={OnePoliceLine} alt="Do not Cross, we are investigating" />
                </div>
            </Container>
        </>
    )
}