import styled from 'styled-components';
import frameShh from '../../assets/frameShh.svg'
import Twitter from '../../assets/Twitter.svg'
import Github from '../../assets/Github.svg'
import Discord from '../../assets/Discord.svg'
import Medium from '../../assets/Medium.svg'
import Telegram from '../../assets/Telegram.svg'


export const Container = styled.div`
    max-width: 100vw !important;
`;


export const FrameContent = styled.div`
    height: 93%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;
    
`;

export const Screen = styled.div`
    background: url(${frameShh}); 
    position: absolute;
    width: 1205px;
    height: 650px;
    left: calc(50% - 1205px/2 - 93.5px);
    top: 232px;

`;

export const TextOne = styled.h1`
    position: relative;
    width: 621.53px;
    height: 36px;
    left: calc(50% - 621.53px/2 + 121.57px);
    top: calc(50% - 36px/2 - 275.96px);

    /* Nail Scratch 30 */

    font-family: Nail Scratch;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 36px;
    /* identical to box height, or 120% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #3D1F01;

    transform: rotate(-1.08deg);

    cursor: pointer;

    :hover {
        color: #6B2434;
    }
`

export const TextTwo = styled.h1`
    position: relative;
    width: 320.52px;
    height: 135px;
    left: calc(50% - 320.52px/2 - 173.94px);
    top: calc(50% - 135px/2 - 157.65px);

    /* Nail Scratch 30 */

    font-family: Nail Scratch;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 36px;
    /* or 120% */

    display: flex;
    align-items: center;

    color: #3D1F01;

    transform: rotate(-5.72deg);


    cursor: pointer;

    :hover {
        color: #6B2434;
    }
`

export const TextTree = styled.h1`
    position: relative;
    width: 294.88px;
    height: 108px;
    left: 693.68px;
    top: -56px;

    font-family: Nail Scratch;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    line-height: 36px;
    /* or 103% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #3D1F01;

    transform: rotate(10.5deg);

    cursor: pointer;

    :hover {
        color: #6B2434;
    }
`

export const TextFour = styled.h1`
    position: relative;
    width: 346px;
    height: 36px;
    left: 497.62px;
    top: +18.32px;

    font-family: Nail Scratch;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 36px;
    /* identical to box height, or 90% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #3D1F01;

    transform: rotate(3.53deg);

    cursor: pointer;

    :hover {
        color: #6B2434;
    }
`

export const TextFive = styled.h1`
    position: relative;
    width: 322.74px;
    height: 108px;
    left: 363.49px;
    top: +76px;

    font-family: Nail Scratch;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 36px;
    /* or 120% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #3D1F01;

    transform: rotate(10.93deg);

    cursor: pointer;

    :hover {
        color: #6B2434;
    }
`

export const TextSix = styled.h1`
    position: relative;
    width: 343.19px;
    height: 144px;
    left: 760.94px;
    top: -55px;

    /* Nail Scratch 30 */

    font-family: Nail Scratch;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 36px;
    /* or 120% */

    display: flex;
    align-items: center;
    text-align: center;

    color: #3D1F01;

    transform: rotate(3.16deg);

    cursor: pointer;

    :hover {
        color: #6B2434;
    }
`

export const TwitterImg = styled.div`
    background: url(${Twitter}); 
    position: relative;
    width: 50px;
    height: 43.5px;
    left: calc(50% - 50px/2 + 162px);
    top: calc(50% - 42.5px/2 - 386.25px);
    cursor: pointer;
`;

export const TelegramImg = styled.div`
    background: url(${Telegram}); 
    position: relative;
    width: 50px;
    height: 41.82px;
    left: calc(50% - 50px/2 - 257px);
    top: calc(50% - 41.82px/2 - 605.91px);
    cursor: pointer;
`;

export const MediumImg = styled.div`
    background: url(${Medium}); 
    position: relative;
    width: 121px;
    height: 35px;
    left: calc(50% - 121px/2 + 226.82px);
    top: calc(50% - 19px/2 - 616.5px);
    cursor: pointer;
`;

export const DiscordImg = styled.div`
    background: url(${Discord}); 
    position: relative;
    width: 60px;
    height: 47.38px;
    left: calc(50% - 50px/2 + 271.66px);
    top: calc(50% - 40.38px/2 - 826.49px);
    cursor: pointer;

`;

export const GithubImg = styled.div`
    background: url(${Github}); 
    position: relative;
    width: 55px;
    height: 72.5px;
    left: calc(50% - 50px/2 - 67px);
    top: calc(50% - 62.5px/2 - 820.99px);
    cursor: pointer;

`;