import styled from 'styled-components';
import ScrollThievesImg from '../../assets/ScrollThieves.svg';
import SmallTagdefaut from '../../assets/SmallTag.svg';
import GetTicketdefaut from '../../assets/buttons/defaut/GetTicket.svg';
import GetTicketpressed from '../../assets/buttons/pressed/GetTicket.svg';
import ApproveScamOMaticdefaut from '../../assets/buttons/defaut/ApproveScamOMatic.svg';
import ApproveScamOMaticpressed from '../../assets/buttons/pressed/ApproveScamOMatic.svg';
import BuyUsdcdefaut from '../../assets/buttons/defaut/BuyUsdc.svg';
import BuyUsdcpressed from '../../assets/buttons/pressed/BuyUsdc.svg';
import ConnectScamdefaut from '../../assets/buttons/defaut/ConnectScam.svg';
import ConnectScampressed from '../../assets/buttons/pressed/ConnectScam.svg';
import SmallFramedefaut from '../../assets/SmallFrame.svg';
import TalkImg from '../../assets/Talk.svg';
import Backdefaut from '../../assets/buttons/defaut/Back.svg';
import Backpressed from '../../assets/buttons/pressed/Back.svg';
import InterDefaut from '../../assets/buttons/defaut/Inter.svg';
import InterPressed from '../../assets/buttons/pressed/Inter.svg';

export const Container = styled.div`
    max-width: 100vw !important;
`;


export const FrameContent = styled.div`
    height: 93%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;
`;

export const ScrollThieves = styled.div`
   background: url(${ScrollThievesImg}); 
   position: relative;
   width: 261px;
   height: 440.22px;
   left: calc(50% - 298px/2 - 317px);
   top: calc(50% - 50px/2 - 195px);

`;

export const TitleOne = styled.h1`
   position: relative;
   width: 221px;
   height: 24px;
   left: calc(50% - 221px/2 - 322.5px);
   top: calc(50% - 24px/2 - 428px);

   /* Fanda Eyptian 24 */


   font-family: Fanda Egyptian;
   font-style: normal;
   font-weight: normal;
   font-size: 24px;
   line-height: 24px;
   
   /* identical to box height */


   /* Red */

   color: #6B2434;
`;

export const TextOne = styled.h2`
   position: relative;
   width: 144px;
   height: 32px;
   left: calc(50% - 144px/2 + 255px);
   top: calc(50% - 32px/2 + 76px);

   /* Alagard 24 */

   font-family: Alagard;
   font-style: normal;
   font-weight: 500;
   font-size: 24px;
   line-height: 25px;
   display: flex;
   align-items: center;
   text-align: center;
   text-shadow: -1px -1px 0px ${({ theme }) => theme.colors.darkblue100};


   color: #46878F;
   
`;

export const TextTwo = styled.h2`
   position: relative;
   width: 49px;
   height: 14px;
   left: calc(50% + 209px/2 - 5px);
   top: calc(50% + 320px/2);

   font-family: Fanda Egyptian;
   font-style: normal;
   font-weight: normal;
   font-size: 16px;
   line-height: 16px;
   display: flex;
   align-items: center;
   text-align: center;

   color: #24103F;

`;

export const TextTree = styled.h2`
   position: relative;
   width: 159px;
   height: 16px;
   left: calc(50% - 159px/2 + 161.5px);
   top: calc(50% - 16px/2 + 94px);

   font-family: Fanda Egyptian;
   font-style: normal;
   font-weight: normal;
   font-size: 16px;
   line-height: 16px;
   /* identical to box height */

   display: flex;
   align-items: center;

   color: #24103F;
`;

export const InputText = styled.h2`
   position: relative;
   width: 43px;
   height: 18px;
   left: calc(50% - 43px/2 - 411.5px);
   top: calc(50% - 18px/2 - 345px);

   /* Fanda Egyptian 18 */

   font-family: Fanda Egyptian;
   font-style: normal;
   font-weight: normal;
   font-size: 18px;
   line-height: 18px;
   /* identical to box height */

   text-align: right;

   /* Shadow */

   color: #24103F;
`;

export const InputElement = styled.input`
   display: flex;
   flex-direction: row;
   align-items: flex-start;
   padding: 6px;

   position: relative;
   width: 171px;
   height: 30px;
   left: calc(50% - 171px/2 - 300.5px);
   top: calc(50% - 30px/2 - 370px);

   border: 1px solid #FFFFFF;
   box-sizing: border-box;
`;

export const SmallTag =styled.div`
   background: url(${SmallTagdefaut}); 
   position: relative;
   width: 274px;
   height: 36px;
   left: calc(50% - 270px/2 + 212px);
   top: calc(50% - 32px/2 + 109px);

`;

export const ButtonGetTicket =styled.div`
   background: url(${GetTicketdefaut}); 
   position: relative;
   width: 200px;
   height: 50px;
   left: calc(50% - 200px/2 + 212px);
   top: calc(50% - 50px/2 + 335px);
   cursor: pointer;

   :active {
      background: url(${GetTicketpressed});
}

`;

export const ButtonBuyUsdc =styled.div`
   background: url(${BuyUsdcdefaut}); 
   position: relative;
   width: 200px;
   height: 50px;
   left: calc(50% - 200px/2 + 212px);
   top: calc(50% - 50px/2 + 335px);
   cursor: pointer;

   :active {
      background: url(${BuyUsdcpressed});
}

`;

export const ButtonConnectScam =styled.div`
   background: url(${ConnectScamdefaut}); 
   position: relative;
   width: 200px;
   height: 50px;
   left: calc(50% - 200px/2 + 212px);
   top: calc(50% - 50px/2 + 335px);
   cursor: pointer;

   :active {
      background: url(${ConnectScampressed});
}

`;

export const ButtonApprove =styled.div`
   background: url(${ApproveScamOMaticdefaut}); 
   position: relative;
   width: 200px;
   height: 50px;
   left: calc(50% - 200px/2 + 212px);
   top: calc(50% - 50px/2 + 335px);
   cursor: pointer;

   :active {
      background: url(${ApproveScamOMaticpressed});
}

`;

export const SmallFrame =styled.div`
   background: url(${SmallFramedefaut});
   position: relative; 
   width: 49px;
   height: 14px;
   left: calc(50% - 49px/2 + 119.5px);
   top: calc(50% - 14px/2 + 182px);


`;

export const Talk =styled.div`
   background: url(${InterDefaut});
   position: relative; 
   width: 67px;
   height: 50px;
   left: calc(50% - 49px/2 - 329.5px);
   top: calc(50% - 14px/2 + 290px);
   cursor: pointer;
   :active {
      background: url(${InterPressed});
   }

`;



export const CloseModalButton = styled.button`
   border: 0;
   outline: 0;
   color: #fff;
   background: none;
   position: absolute;
   left: 95.5%;
   font-family: Fanda Egyptian;
   top: 2.5%;
   font-size: 24px;
   cursor: pointer;
`;

export const Back = styled.div`
   background: url(${Backdefaut}); 
    position: relative;
    width: 67px;
    height: 50px;
    left: calc(50% - 298px/2 - 295px);
    top: calc(50% - 50px/2 + 259px);
    cursor: pointer;

    :active {
      background: url(${Backpressed});
   }
   
`; 