import styled from "styled-components";
import CheckButton from '../../../../assets/checkButton.svg';
import PressedCheckButton from '../../../../assets/PressedCheckButton.svg';


export const Container = styled.button`
   background: url(${CheckButton}) no-repeat; 
   position: absolute;
   outline: 0;
   border: 0;
   width: 200px;
   height: 50px;
   left: calc(50% - 200px/2);
   top: 66%;
   cursor: pointer;
   font-family: Fanda Egyptian;
   font-size: 24px;
   color: ${({ theme }) => theme.colors.white};

   :active {
      background: url(${PressedCheckButton}) no-repeat;
   }



`;
