import styled from 'styled-components';
import OrangeButtonImage from '../../../assets/buttons/BackOrange.svg';
import BackGreenButtonImage from '../../../assets/buttons/BackGreen.svg';
import BackBleuButtoImage from '../../../assets/buttons/Blue.svg';


export const Container = styled.div`
    max-width: 100vw !important;
`;


export const FrameContent = styled.div`
    height: 93%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;
`;

export const TitleOne = styled.h1`
    position: relative;
    width: 270px;
    height: 31px;
    left: calc(50% - 270px/2 - 206px);
    top: calc(50% - 31px/2 + 58.5px);

    /* Alagard */

    font-family: Alagard;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 31px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* Shadow */

    color: #24103F;

`;

export const TextOne = styled.h3`
    position: relative;
    width: 357px;
    height: 396.06px;
    left: calc(50% - 357px/2 - 162.5px);
    top: calc(50% - 396.06px/2 + 78.97px);
    
    /* Fanda Egyptian 18 */
    
    font-family: Fanda Egyptian;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    
    /* Shadow */
    
    color: #24103F;
`;

export const TitleTwo = styled.h1`
    position: relative;
    width: 122px;
    height: 31px;
    left: calc(50% - 122px/2 + 119px);
    top: calc(50% - 31px/2 - 318px);

    /* Alagard */

    font-family: Alagard;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 31px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* Shadow */

    color: #24103F;
`;

export const TextTwo = styled.h3`
    position: relative;
    width: 362px;
    height: 290px;
    left: calc(50% - 362px/2 + 239px);
    top: calc(50% - 290px/2 - 292px);

    /* Fanda Egyptian 18 */

    font-family: Fanda Egyptian;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;

    /* Shadow */

    color: #24103F;
`;

export const NextPage = styled.h3`
    position: relative;
    width: 129px;
    height: 15px;
    left: calc(50% - 129px/2 + 265.5px);
    top: calc(50% - 15px/2 - 312.5px);

    /* Fanda Egyptian 18 */

    font-family: Fanda Egyptian;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    cursor: pointer;

    color: #DCA456;

`;

export const PrevewsPage = styled.h3`
    position: relative;
    width: 151px;
    height: 15px;
    left: calc(50% - 151px/2 - 181.5px);
    top: calc(50% - 15px/2 - 267.5px);
    cursor: pointer;

    /* Fanda Egyptian 18 */

    font-family: Fanda Egyptian;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;

    color: #DCA456;
`;
export const ExitBlue = styled.div`
   position: relative;
    width: 32.89px;
    height: 40px;
    left: calc(50% - 32.89px/2 + 452.44px);
    top: calc(50% - 40px/2 - 549px);

`;
export const ExitOrange = styled.div`
    position: relative;
    width: 24.89px;
    height: 40px;
    left: calc(50% - 24.89px/2 + 449.44px);
    top: calc(50% - 40px/2 - 679px);
`;

export const BackOrangeButton = styled.button`
   background: url(${OrangeButtonImage}) no-repeat center; 
   position: relative;
    width: 61px;
    height: 40px;
    left: calc(50% - 61px/2 - 417.5px);
    top: calc(50% - 40px/2 - 694px);
    cursor: pointer;
`;

export const BackGreenButton = styled.button`
   background: url(${BackGreenButtonImage}) no-repeat center; 
   position: relative;
    width: 61px;
    height: 40px;
    left: calc(50% - 61px/2 - 417.5px);
    top: calc(50% - 40px/2 - 709px);
    cursor: pointer;

`;


export const BackBleuButto = styled.button`
   background: url(${BackBleuButtoImage}) ; 
   position: relative;
    width: 53px;
    height: 40px;
    left: calc(50% - 53px/2 - 413.5px);
    top: calc(50% - 40px/2 - 685px);
    cursor: pointer;
`;