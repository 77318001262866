import styled from 'styled-components';
import ConnectedImg from '../../assets/Connected.svg'

export const Container = styled.nav`
    height: 6.5rem;
    width: 100%;
    display: grid;
    grid: 1fr 3fr 1fr;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: ${({ theme }) => theme.colors.green100};
    font-size: 1.7rem;
    padding: 0 1.3rem;
    font-weight: 500;

    div {
        display: flex;
        align-items: center;
        margin: 0 1rem;

        &:first-child,
        &:last-child {
            max-width: 80px;
        }

        &:last-child {
            margin-left: -60px;
        }

    }

    p {
        margin-left: 14px;
    }
    strong {
        color: ${({ theme }) => theme.colors.gold80};
        text-shadow: 0px 2px 0px #24103F;
        text-align: center;

        &:first-of-type {
            margin: 0 0.5rem;
        }

        cursor: pointer;
    }

    button {
        width: 190px;
        height: 30px;
        font-size: 1.75rem;
        margin-left: 1.5rem;
    }

`
export const LinkInstallMetaMask = styled.a`
  background-color: transparent;
  border: 1px solid #26DBDB;
  border-radius: 8px;
  color:  #26DBDB !important;
  font-size: 16px;
  text-transform: none;
  text-align: center;

  width: 186px;
  height: 48px;
  padding: 12px 0;
  cursor: pointer;
  &:hover {
    background-color:  #26DBDB;
    color: #211426 !important;
  }
`;

export const Connecteded = styled.a`
    background: url(${ConnectedImg});

    position: relative;
    width: 190.09px;
    height: 31px;
    left: 0px;
    top: -10px;


    /* Inside Auto Layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 2px 0px;
`;