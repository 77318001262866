import { Background } from './styles';

export default function OpenButton(props: any) {
    return (
        <>
            <Background>
                {props.children}
            </Background>
        </>
    )
}