import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: space-around;
    font-family: Nail Scratch;
    font-style: normal;
    font-weight: normal;
    position: relative;
    height: 539px;
    left: calc(50% - 945px/2 - 0.5px);
    top: -1px;
    background-color: rgba(0,0,0,1);
    width: 945px;

    h1 {
        font-size: 120px;
        color: #CC2D2D;
        transform: rotate(-15deg);
        position: absolute;
        left: 280px;
        top: 160px;
    }

    h2 {
        font-size: 50px;
        color: #fff;
        transform: rotate(-10deg);
        position: absolute;
        left: 400px;
        top: 310px;
    }
`;