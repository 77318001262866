import styled from 'styled-components';
import ForwardButton from '../../../../assets/ForwardButton.svg';
import ForwardPressedButton from '../../../../assets/ForwardPressedButton.svg';
import BackwardButton from '../../../../assets/BackwardButton.svg';
import BackwardPressedButton from '../../../../assets/BackwardPressedButton.svg';


export const FrameContent = styled.div`
    height: 93%;
    padding-top: 70px;
    max-width: 100%;
    z-index: 1;

    p {
        margin-bottom: 25px;
        margin-top: 45px;
    }

    small {
        margin: 15px 0 10px 30px;
    }

    .swiper-container {
        max-width: 946px;
        cursor: pointer;
    }

    .swiper-button-prev {
        background: url(${BackwardButton}) no-repeat;
        position: absolute;
        border: 0;
        top: 40%;
        width: 64px;
        left: 16%;
        height: 200px;
        cursor: pointer;
        :active {
            background: url(${BackwardPressedButton});
        }
        :after, .swiper-container-rtl .swiper-button-prev::after {
            content: '';
        }
    }

    .swiper-button-next {
        background: url(${ForwardButton}) no-repeat;
        border: 0;
        position: absolute;
        top: 40%;
        right: 16%;
        width: 64px;
        height: 200px;
        cursor: pointer;
        z-index: 100;
        :active {
            background: url(${ForwardPressedButton});
        }
        :after, .swiper-container-rtl .swiper-button-prev::after {
            content: '';
        }
    }
    
`;

export const ArtworkContainer = styled.div`
`;

export const ArtworkContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 480px;
    height: 100%;

    img {
        width: 338px;
        height: 476px;
        display: flex;
        justify-content: center;
    }

    span {
        color: #fff;
        font-size: 18px;
        margin: 10px 0;
    }
`;