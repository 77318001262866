import styled  from "styled-components";
import artworkScroll from '../../assets/Scroll/artworkScroll.svg';

export const ScrollContainer = styled.div`
   background: url(${artworkScroll}) no-repeat;
   position: absolute;
   width: 280px;
   height: 460px;
   top: 28%;
   left: 56.5%;
   display: flex;
   flex-direction: column;

   p {
      color: ${({ theme }) => theme.colors.red20};
      margin-left: 28px;
      font-size: 24px;
      font-family: Fanda Egyptian;
      line-height: 0.1;
   }

   small {
      color: ${({ theme }) => theme.colors.black};
      font-size: 18px;
      margin-left: 30px;
      font-family: Fanda Egyptian;
      line-height: 0.1;
      margin: 30px 0 30px 30px;
   }

   ul {
      margin-left: 30px;
      font-family: Fanda Egyptian;
      font-size: 18px;
      list-style: none;
            
      li {
         margin: 6px 0;
      }
   }
`;