import styled from 'styled-components';
import ScrollThievesImg from '../../assets/ScrollThieves.svg';
import ContractThendefaut from '../../assets/buttons/defaut/ContractThen.svg';
import ContractThenpressed from '../../assets/buttons/pressed/ContractThen.svg';
import FuckItdefaut from '../../assets/buttons/defaut/FuckIt.svg';
import FuckItpressed from '../../assets/buttons/pressed/FuckIt.svg';
import Backdefaut from '../../assets/buttons/defaut/Back.svg';
import Backpressed from '../../assets/buttons/pressed/Back.svg';
import TalkImg from '../../assets/buttons/TalkLeft.svg';

export const Container = styled.div`
    max-width: 100vw !important;
`;


export const FrameContent = styled.div`
    height: 93%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;
`;

export const ScrollThieves = styled.div`
   background: url(${ScrollThievesImg}); 
   position: relative;
   width: 261px;
   height: 440.22px;
   left: calc(50% - 298px/2 - 317px);
   top: calc(50% - 50px/2 - 265px);

`;

export const TitleOne = styled.h1`
   position: relative;
   width: 221px;
   height: 24px;
   left: calc(50% - 221px/2 - 322.5px);
   top: calc(50% - 24px/2 - 498px);

   /* Fanda Eyptian 24 */

   font-family: Fanda Egyptian;
   font-style: normal;
   font-weight: normal;
   font-size: 24px;
   line-height: 24px;
   /* identical to box height */


   /* Red */

   color: #6B2434;
`;

export const TextOne = styled.h2`
   position: relative;
   width: 221px;
   height: 90px;
   left: calc(50% - 221px/2 - 322.5px);
   top: calc(50% - 90px/2 - 473px);

   /* Fanda Egyptian 18 */

   font-family: Fanda Egyptian;
   font-style: normal;
   font-weight: normal;
   font-size: 18px;
   line-height: 18px;

   /* Shadow */

   color: #24103F;
`;

export const TitleTwo = styled.h1`
   position: relative;
   width: 221px;
   height: 24px;
   left: calc(50% - 230px/2 - 322.5px);
   top: calc(50% - 24px/2 - 404px);

   /* Fanda Eyptian 24 */

   font-family: Fanda Egyptian;
   font-style: normal;
   font-weight: normal;
   font-size: 24px;
   line-height: 24px;
   /* identical to box height */


   /* Red */

   color: #6B2434

`;

export const InputText = styled.h2`
   position: relative;
   width: 43px;
height: 18px;
left: calc(50% - 43px/2 - 411.5px);
top: calc(50% - 18px/2 - 385px);

/* Fanda Egyptian 18 */

font-family: Fanda Egyptian;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 18px;
/* identical to box height */

text-align: right;

/* Shadow */

color: #24103F;
`;

export const InputElement = styled.input`
   display: flex;
   flex-direction: row;
   align-items: flex-start;
   padding: 6px;

   position: relative;
   width: 164px;
   height: 30px;
   left: calc(50% - 171px/2 - 300.5px);
   top: calc(50% - 30px/2 - 410px);

   border: 1.25px solid ${({ theme }) => theme.colors.gold100};
   box-sizing: border-box;

   &::-webkit-outer-spin-button,
   &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
   } 
   
   &[type=number]{
    -moz-appearance: textfield;
   }

   &:focus {
      outline: 0;
   }
`;

export const ButtonContractThen =styled.div`
background: url(${ContractThendefaut}); 
position: relative;
width: 218px;
height: 50px;
left: calc(50% - 218px/2 - 324px);
top: calc(50% - 50px/2 - 395px);
 cursor: pointer;

 :active {
   background: url(${ContractThenpressed});
}

`;

export const ButtonFuckIt =styled.div`
background: url(${FuckItdefaut}); 
position: relative;
width: 218px;
height: 50px;
left: calc(50% - 218px/2 - 324px);
top: calc(50% - 50px/2 - 385px);
 cursor: pointer;

 :active {
   background: url(${FuckItpressed});
}

`;

export const Back = styled.div`
   background: url(${Backdefaut}); 
    position: relative;
    width: 67px;
    height: 50px;
    left: calc(50% - 298px/2 - 295px);
    top: calc(50% - 50px/2 - 259px);
    cursor: pointer;

    :active {
      background: url(${Backpressed});
   }
   
`; 

export const CloseModalButton = styled.button`
   border: 0;
   outline: 0;
   color: #fff;
   background: none;
   position: absolute;
   left: 95.5%;
   font-family: Fanda Egyptian;
   top: 2.5%;
   font-size: 24px;
   cursor: pointer;
`;

export const TalkLeft =styled.div`
   background: url(${TalkImg});
   position: relative; 
   width: 84px;
   height: 45px;
   left: calc(50% - 49px/2 + 29.5px);
   top: calc(50% - 14px/2 - 512px);
   cursor: pointer;


`;