import { useHistory, useParams } from "react-router-dom";
import { Container, StealButton, Back, SlotContainer, ArtworkContainer, StealApproveButton, BuyTicket, ReturnApproveButton, ReturnButton, Connect } from './styles';
import JumboSlotUp from '../../../../assets/JumboSlotUp.svg'
import JumboSlotSu from '../../../../assets/JumboSlotSu.svg'
import JumboSlotFa from '../../../../assets/JumboSlotFa.svg'
import JumboSlotAction from '../../../../assets/JumboSlotAction.gif'
import { useContext, useEffect, useState } from "react";
import { ArtworkContext } from "../../../../context/ArtworkProvider";
import { MachineScroll } from "../../../../components/MachineScroll";
import React from 'react'
import web3 from '../../../../components/utils/web3'
import {Louvre, PriceLP, Ticket, LART} from '../../../../components/constants/tokenAddresses';
import { BNtoDecimal } from '../../../../components/utils/numerals';
import BigNumber from 'bn.js'
import useLouvre from '../../../../components/hooks/useLouvre';
import usePriceLP from '../../../../components/hooks/usePriceLP';
import useLocalStorage from '../../../../components/LocalStorage/useLocalStorage'
import useERC20Contract from "../../../../components/hooks/useERC20Contract";
import useLART from "../../../../components/hooks/useLART";
import RobbedArtwork from "../Robbed";
import useBalance from "../../../../components/hooks/useBalance";
import useConnect from "../../../../components/hooks/useConnect";

export interface IArtworked {
    id: number;
    Floor: number;
    Title: string;
    Description: string;
    Element: string;
    Robbed?: boolean;
    attempt: number;
    robbedTimes: number;
    probability: number;
    reward: number;
  }
  

type ContentParams = {
    id: string;
}
export default function Content() {
    const history = useHistory();
    const { id } = useParams<ContentParams>();
    const [art, setArt] = useState(Number(id));


    const [hired, setHired] = useLocalStorage('hired', 0)
    const [timeMachine, setTimeMachine] = useLocalStorage('timeMachine', 0)
    const [concludMachine, setConcludMachine] = useLocalStorage('concludMachine', false)
    const [isLogged, setisLogged] = useLocalStorage('loading', false)

    const { artwork, getArtworkById} = useContext(ArtworkContext);
    const [ flore, setFlore ]  = React.useState<any[]>([{id: 53}])
    const [ reserve, setReserves ]  = React.useState(0)
    const [ isApproveTicket, setisApproveTicket ]  = React.useState(Boolean(false))
    const [ isApproveNFT, setisApproveNFT ]  = React.useState(Boolean(false))
    const [backgroundImage, setBackgroundImage] = React.useState(JumboSlotUp)
    const [multiplier, setMultiplier] = React.useState(0)
    const [bBalance, setBBalance] = React.useState(0)
    


    const forceUpdate: () => void = React.useState(Array)[1].bind(null, [])



    const {viewNumberOfAttempts, multiplierFee, viewNumberOfSucesses, viewProbability, viewReward, viewNFTStatus, initializeAttempt, claimReward} = useLouvre()
    const { viewgetReserves } = usePriceLP()
    const { approve, getAllowance } = useERC20Contract()
    const { approveNFT, getAllowanceNFT } = useLART()
    const { getBalanceToken } = useBalance()
    const { Message, SignIn } = useConnect()

    function sleep(delay: number) {
        var start = new Date().getTime();
        while (new Date().getTime() < start + delay);
    }

    async function handleApproveTicket() {
        if (isApproveTicket) {
          return true
        }else{
        const res = await approve(Louvre, Ticket)
        setisApproveTicket(res)
    }}

    async function handleApproveNFT(nftId: number) {
        if (isApproveNFT) {
          return true
        }else{
        const res = await approveNFT(Louvre, nftId, LART)
        setisApproveNFT(res)
    }}

    async function reward(id: number) {
        const attempts = await claimReward(Louvre, id)
        return attempts
    }

    async function fee(hire: number) {
        const multiplier = await multiplierFee(Louvre, hire)
        const bMultiplier = BNtoDecimal(multiplier, new BigNumber(18), 2)
        setMultiplier(Number(bMultiplier))
    }

    async function balance() {
        const balance = await getBalanceToken(Ticket)
        const bBalance = Number(BNtoDecimal(balance, new BigNumber(18), 2))
        setBBalance(bBalance)
    }
    
    async function updateNumberofAttempts(id: number) {
        const attempts = await viewNumberOfAttempts(Louvre, id)
        return attempts
    }

    async function updateNumberOfRobbed(id: number) {
        const attempts = await viewNumberOfSucesses(Louvre, id)
        return attempts
    }

    async function updateNumberOfProbability(id: number, hired: number) {
        const attempts = await viewProbability(Louvre, id, hired)
        return attempts
    }

    async function updateNumberOfReward(id: number) {
        const attempts = await viewReward(Louvre, id)
        return attempts
    }

    async function updateRobbed(id: number) {
        const attempts = await viewNFTStatus(Louvre, id)
        return attempts
    }

    async function handlegetReserves() {
        const balance = await viewgetReserves(PriceLP)
        const dolar = balance[1] / 1000000
        const ticket = balance[0] / 1000000000000000000
        setReserves(dolar/ticket)
    }


    async function updateFloor(attempts: any[], robbedTimes: any[], probability: any[], reward: any[], robbed: any[]) {
        const Floor = new Array()
        getArtworkById(art)

        Floor.push(artwork)            

       

        const mergeById = (a1: any[], a2: any[]) =>
            a1.map(itm => ({
                ...a2.find((item) => (item.id === itm.id) && item),
                ...itm
            }));

        
        const Floor1 = mergeById(mergeById(mergeById(mergeById(Floor, attempts), robbedTimes), probability), reward)
        const Floor2 = mergeById(robbed, Floor1)

        setFlore(Floor2)
    }

    React.useEffect(() => {
        (async () => {
        forceUpdate()
        getAllowance(Louvre, Ticket)
            .then((response: boolean) => setisApproveTicket(response)) 
        sleep(90)
        getAllowanceNFT(art, LART)
            .then((responsee: boolean) => setisApproveNFT(responsee))
        sleep(90)
        await Promise.resolve(art)
        getArtworkById(art)
        handlegetReserves()
        var attempts: any[] = []
        var RobbedTimes: any[] = []
        var probability: any[] = []
        var reward: any[] = []
        var robbed: any[] = []
        if(web3.currentProvider !== null){
            const ida = art
            const numberAttempst = await Promise.resolve(updateNumberofAttempts(art))
            sleep(90)
            attempts.push({id: ida, attempt: Number(numberAttempst)})
            const numberRobbed = await Promise.resolve(updateNumberOfRobbed(art))
            sleep(90)
            RobbedTimes.push({id: ida, robbedTimes: Number(numberRobbed)})
            const numberProbability = await Promise.resolve(updateNumberOfProbability(art, (hired+1)))
            sleep(90)
            probability.push({id: ida, probability: Number(numberProbability/10000)})
            const numberReward = await Promise.resolve(updateNumberOfReward(art))
            sleep(90)
            const bigNumberReward = BNtoDecimal(numberReward, new BigNumber(18), 2)
            reward.push({id: ida, reward: Number(bigNumberReward)})
            const robbeded = await Promise.resolve(updateRobbed(art))
            sleep(90)
            robbed.push({id: ida, Robbed: !robbeded})
        }
        fee(hired+1)
        balance()
        switch(timeMachine){
            case 0:
                setBackgroundImage(JumboSlotUp)
                break;
            case 1:
                setBackgroundImage(JumboSlotAction)
                break;
            case 2:
                if (concludMachine === true){
                    setBackgroundImage(JumboSlotSu)
                } else {
                    setBackgroundImage(JumboSlotFa)
                }
                break;
            default:
                setBackgroundImage(JumboSlotUp)
                break;
            }
        updateFloor(attempts, RobbedTimes, probability, reward, robbed)
    })()
    },[art, flore, timeMachine]);

        return (
            <Container>
                <SlotContainer>
                    {concludMachine === false ? 
                    flore[0].Robbed === false? 
                    <div>
                    <img src={backgroundImage} alt="Jumbo Slot, Rob a' Chance" />
                    </div>
                    :
                    <div></div>
                    :
                    <div>
                    <img src={JumboSlotSu} alt="Jumbo Slot, Rob a' Chance" />
                    </div>
                    }
                    
                </SlotContainer>
                
                {flore[0].Robbed === false? 
                flore[0].Title === undefined ? 
                <div></div>
                :
                <ArtworkContainer>
                    <img src={require(`../../../../assets/Artworks/${flore[0]?.Floor}/${flore[0]?.Title.replace(/ /g, '')}.png`).default} alt={artwork?.Title} />
                </ArtworkContainer>
                :
                flore[0].Title === undefined ? 
                <div></div>
                :
                <div>
                <RobbedArtwork />
                <ArtworkContainer>
                    <img src={require(`../../../../assets/Artworks/${flore[0]?.Floor}/${flore[0]?.Title.replace(/ /g, '')}.png`).default} alt={artwork?.Title} />
                </ArtworkContainer>
                </div>}
                <MachineScroll>
                    <p>{flore[0].Title}</p>
                    <span>{flore[0].Description}</span>
                    <small>Stolen: {flore[0].robbedTimes} times</small>
                    <small>Attempts: {flore[0].attempt}</small>
                    <small>Sucess rate: {flore[0].Robbed === true ? 0 : `${flore[0].probability}`}%</small>
                    <small>Reward: {(flore[0].reward)} TICKET</small>
                    {isLogged === true ? 
                        flore[0].Robbed === true ? 
                            isApproveNFT === false ? 
                                <ReturnApproveButton onClick={() => handleApproveNFT(flore[0].id)}></ReturnApproveButton> 
                                : 
                                <ReturnButton onClick={() => {reward(flore[0].id); setTimeMachine(0)}}></ReturnButton> 
                        : 
                        ((multiplier+hired+1) <= bBalance) ? 
                            isApproveTicket === false ? 
                                <StealApproveButton onClick={() => handleApproveTicket()}/> 
                                :
                                <StealButton onClick={() =>  {initializeAttempt(Louvre, flore[0].id, (hired+1))}} />
                            : 
                        bBalance === 0 ?
                            <BuyTicket  onClick={() => {window.open('https://quickswap.exchange/#/swap?outputCurrency=0x0514C869D3E0f553Edf590093914Abe91D9D0611', '_blank');}}/> 
                            :
                            <div>
                            <BuyTicket  onClick={() => {window.open('https://quickswap.exchange/#/swap?outputCurrency=0x0514C869D3E0f553Edf590093914Abe91D9D0611', '_blank');}}/> 
                            <span>or  contract  fewer  thieves</span>
                            </div>
                        : 
                        <Connect onClick={() => SignIn()} />} 
                </MachineScroll>
                <div>
                    <Back onClick={() => history.push('/play')}></Back>
                </div>
    
            </Container>
        )
    }
