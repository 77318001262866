import styled from 'styled-components';
import BuyTicketdefaut from '../../../assets/buttons/defaut/BuyTicket.svg';
import BuyTicketpressed from '../../../assets/buttons/pressed/BuyTicket.svg';
import StakeLpUnlockeddefaut from '../../../assets/buttons/defaut/StakeLp1.svg';
import StakeLpUnlockedpressed from '../../../assets/buttons/pressed/StakeLp1.svg';
import StakeLpLockeddefaut from '../../../assets/buttons/defaut/StakeLpLocked.svg';
import StakeLpLockedpressed from '../../../assets/buttons/pressed/StakeLpLocked.svg';
import Backdefaut from '../../../assets/buttons/defaut/Back.svg';
import Backpressed from '../../../assets/buttons/pressed/Back.svg';
import InterDefaut from '../../../assets/buttons/defaut/Inter.svg';
import InterPressed from '../../../assets/buttons/pressed/Inter.svg';
import AddMetamaskDefaut from '../../../assets/buttons/defaut/AddTicket.svg'
import AddMetamaskPressed from '../../../assets/buttons/pressed/AddTicket.svg'
import FarmTicketsDefaut from '../../../assets/buttons/defaut/FarmTickets.svg'
import FarmTicketsPressed from '../../../assets/buttons/pressed/FarmTickets.svg'



export const Container = styled.div`
    max-width: 100vw !important;
`;


export const FrameContent = styled.div`
    height: 93%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;
`;

export const Title = styled.h1`
    position: relative;
    width: 552px;
    height: 62px;
    left: calc(50% - 274px/2 + 30px);
    top: calc(50% - 62px/2 + 39px);

    font-family: Alagard;
    font-style: normal;
    font-weight: 500;
    font-size: 60px;
    line-height: 62px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: right;

    color: #C4C4C4;

    text-shadow: 0px 4px 0px #6B2434;
`;

export const Text1 = styled.h1`
    position: relative;
    width: 352px;
    height: 157px;
    left: calc(50% - 352px/2 + 264px);
    top: calc(50% - 157px/2 + 61.5px);

    /* Fanda Eyptian 24 */

    font-family: Fanda Egyptian;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    text-align: right;

    color: #FFFFFF;
`;

export const Text2 = styled.h1`
    position: relative;
    width: 352px;
    height: 157px;
    left: calc(50% - 352px/2 + 264px);
    top: calc(50% - 157px/2 + 61.5px);

    /* Fanda Eyptian 24 */

    font-family: Fanda Egyptian;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    text-align: right;

    color: #FFFFFF;
`;

export const AprText1 = styled.h1`
    position: relative;
    width: 352px;
    height: 157px;
    left: calc(50% - 352px/2 + 264px);
    top: calc(50% - 157px/2 - 351.5px);

    /* Fanda Eyptian 24 */

    font-family: Fanda Egyptian;
   font-style: normal;
   font-weight: normal;
   font-size: 18px;
   line-height: 18px;
   /* identical to box height */

   text-align: right;

   color: #FF924F;
`;

export const AprText2 = styled.h1`
    position: relative;
    width: 352px;
    height: 157px;
    left: calc(50% - 352px/2 + 264px);
    top: calc(50% - 157px/2 - 351.5px);

    /* Fanda Eyptian 24 */

    font-family: Fanda Egyptian;
   font-style: normal;
   font-weight: normal;
   font-size: 18px;
   line-height: 18px;
   /* identical to box height */

   text-align: right;

   color: #FF924F;
`;

export const BuyTicket = styled.div`
   background: url(${BuyTicketdefaut}); 
   position: relative;
    width: 298px;
    height: 50px;
    left: calc(50% - 298px/2 + 287px);
    top: calc(50% - 50px/2 + 53px);
    cursor: pointer;

    :active {
      background: url(${BuyTicketpressed});
   }
   
`;

export const StakeLpUnlocked = styled.div`
   background: url(${StakeLpUnlockeddefaut}); 
   position: relative;
    width: 298px;
    height: 50px;
    left: calc(50% - 298px/2 + 298px);
    top: calc(50% - 50px/2 - 216px);
    cursor: pointer;

    :active {
      background: url(${StakeLpUnlockedpressed});
   }
   z-index: 100;
`;

export const StakeLpLocked = styled.div`
   background: url(${StakeLpLockeddefaut}); 
   position: relative;
    width: 298px;
    height: 50px;
    left: calc(50% - 298px/2 + 298px);
    top: calc(50% - 50px/2 - 113px);
    cursor: pointer;

   :active {
      background: url(${StakeLpLockedpressed});
   }
   z-index: 100;
`;

export const AddMetamask = styled.div`
   background: url(${AddMetamaskDefaut}); 
   position: relative;
    width: 298px;
    height: 50px;
    left: calc(50% - 298px/2 + 287px);
    top: calc(50% - 50px/2 + px);
    cursor: pointer;

   :active {
      background: url(${AddMetamaskPressed});
   }
   
`;

export const Back = styled.div`
   background: url(${Backdefaut}); 
   position: relative;
    width: 67px;
    height: 50px;
    left: calc(50% - 298px/2 - 287px);
    top: calc(50% - 50px/2 - 1px);
    cursor: pointer;

   :active {
      background: url(${Backpressed});
   }
   
`;

export const CloseModalButton = styled.button`
   border: 0;
   outline: 0;
   color: #fff;
   background: none;
   position: absolute;
   left: 95.5%;
   font-family: Fanda Egyptian;
   top: 2.5%;
   font-size: 24px;
   cursor: pointer;
`;

export const TalkLeft =styled.div`
   background: url(${InterDefaut});
   position: relative; 
   width: 67px;
   height: 50px;
   left: calc(50% - 49px/2 - 329.5px);
   top: calc(50% - 14px/2 - 70px);
   cursor: pointer;
   :active {
      background: url(${InterPressed});
   }

`;

export const FarmTickets = styled.div`
   background: url(${FarmTicketsDefaut}); 
   position: relative;
    width: 298px;
    height: 50px;
    left: calc(50% - 298px/2 + 287px);
    top: calc(50% - 50px/2 + 75px);
    cursor: pointer;

   :active {
      background: url(${FarmTicketsPressed});
   }
   
`;