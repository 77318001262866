import { Container } from './styles';

export default function FrameBadge(props: any) {
    return (
        <>
            <Container>
                {props.children}
            </Container>
        </>
    )
}