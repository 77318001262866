import styled from "styled-components";
import marketStats from '../../../../assets/buttons/Red.svg';

export const Background = styled.button`
   background: url(${marketStats}) no-repeat center; 
   position: relative;
    width: 26.67px;
    height: 40px;
    left: calc(50% - 26.67px/2 + 450.33px);
    top: calc(50% - 40px/2 - 541px);
    cursor: pointer;
`;
