import React from 'react';
import logo from '../../assets/logo.svg'
import navSeparator from '../../assets/navSeparator.svg';
import MarketStatsButton from '../../components/MarketInfo/MarketStatsButton';
import { MobileScroll } from '../../components/MobileScroll';
import { Scroll } from '../../components/Scroll';
import { Container, MobileNav } from './styles';

const Mobile: React.FC = () => {


    return (
        <Container>
            <MobileNav>
                <img src={logo} alt="Louvre Finance" />
                <strong><p>Louvre</p> Finance</strong>
            </MobileNav>
            <img className="separator" src={navSeparator} alt="Nav separator" />
            <MobileScroll/>
        </Container>
    )
}

export default Mobile;