import React from 'react'
import BigNumber from 'bn.js'
import { AbiItem } from "web3-utils"
import useLocalStorage from '../LocalStorage/useLocalStorage'
import useConnect from './useConnect'
import { BrowserRouter as Router, Link, Switch, Route } from "react-router-dom";


import { ToastContainer, toast, } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import toastImg from '../../assets/Toast.svg'


import web3 from '../utils/web3'
import web3event from '../utils/web3event'
import TokenSale from "../constants/abi/TokenSale.json"
import { any } from 'glamor'

const useTSContrat = () => {
    const { Message } = useConnect()

    const [userWalletAddress, setUserWalletAddress] = useLocalStorage('userAddress', '')

    const getTSContract = (address: string) => {
        const contract = new web3.eth.Contract((TokenSale.abi as unknown) as AbiItem, address)
        return contract
    }

    const getTSContractWss = (address: string) => {
        const contract = new web3event.eth.Contract((TokenSale.abi as unknown) as AbiItem, address)
        return contract
    }

    const buyToken = async (addressTS: string) => {
        const contract = await getTSContract(addressTS)
        return await contract.methods.buyToken().send({ from: userWalletAddress, gas: 270804, gasPrice: 100000000031  })
        .on('transactionHash', function(hash: any){
            Message([{head: 'Transaction Sent', body: <a onClick= {() => {window.open(`https://polygonscan.com/tx/${hash}`, '_blank')}}>See-on-PolygonSCAM</a> , variant:'info'}])
        })
        .on('receipt', function(receipt: any){
            const res = receipt.events.PurchaseExecuted.returnValues._bool
            if (res === true){
                Message([{head : "Congratulations", body: 'Tanks for the cash, Loser', variant: 'success'}])
            }else{
                Message([{head : "Failure", body: 'Try again, someone was faster than you, Loser', variant: 'warning'}])
            }
        })
        }
//maxPriorityFeePerGas: null, maxFeePerGas: null 1,500000031  [0] some((element: string) => element === 'PurchaseExecuted')  gas: 270804, gasPrice: 1500000031 
    const viewCooldown = async (addressTS: string) => {
        const contract = await getTSContractWss(addressTS)
        const value = await contract.methods.viewCooldown().call({ from: userWalletAddress })
        return (value)
    }
    
    const viewlastSale = async (addressTS: string) => {
        const contract = await getTSContractWss(addressTS)
        const value = await contract.methods.lastSale().call({ from: userWalletAddress })
        return (value)
    }

    const viewtokenPrice = async (addressTS: string) => {
        const contract = await getTSContractWss(addressTS)
        const value = await contract.methods.tokenPrice().call({ from: userWalletAddress })
        return new BigNumber(value)
    }

    const viewtokensSold = (addressTS: string) => {
        const contract = getTSContractWss(addressTS)
        return (contract.methods.tokensSold().call({ from: userWalletAddress }))
    }

    return { 
        getTSContract,
        buyToken,
        viewCooldown,
        viewlastSale,
        viewtokenPrice,
        viewtokensSold
      }
}

export default useTSContrat