import React, { createContext, useCallback, useContext, useState } from "react";
import artworkData from '../services/mockedArtwork.json';

export interface IArtwork {
  id: number;
  Floor: number;
  Title: string;
  Description: string;
  Element: string;
  Robbed?: boolean;
}

interface ArtworkContextData { artworks: IArtwork[], getArtworkById(id: number): void | IArtwork , artwork: IArtwork | void};

export const ArtworkContext = createContext<ArtworkContextData>(
    {} as ArtworkContextData
  );

export const ArtworkProvider: React.FC = ({ children }) => {
    const [artworks, setArtworks] = useState<IArtwork[]>(artworkData)
    const [artwork, setArtwork] = useState<IArtwork>(artworkData[0]);

    const getArtworkById = useCallback((id: number) => {
      const ArtworkData:any | IArtwork = artworkData.find((Artwork: IArtwork): boolean => Artwork.id === id);
  
      setArtwork(ArtworkData);
  
    }, []);


  return (
    <ArtworkContext.Provider value={{ artworks, getArtworkById, artwork }}>
        {children}
    </ArtworkContext.Provider>
  );
};