import { ButtonHTMLAttributes } from 'react'
import { MyButton } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

export default function ButtonGame({ children, ...props }: ButtonProps) {
  return (
    <MyButton
      {...props}>
      <svg className="up-left" width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
          <path d="M1.99994 2H-6.10352e-05V6H1.99994V8H5.99994V6H7.99994V4V2H5.99994V0H3.99994H1.99994V2Z" fill="#CA9219" />
        </g>
        <defs>
          <filter id="filter0_d" x="-6.10352e-05" y="0" width="9" height="9" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
            <feOffset dx="1" dy="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.141176 0 0 0 0 0.0627451 0 0 0 0 0.247059 0 0 0 1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
        </defs>
      </svg>

      <svg className="down-left" width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
          <path d="M1.99994 2H-6.10352e-05V6H1.99994V8H5.99994V6H7.99994V4V2H5.99994V0H3.99994H1.99994V2Z" fill="#CA9219" />
        </g>
        <defs>
          <filter id="filter0_d" x="-6.10352e-05" y="0" width="9" height="9" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
            <feOffset dx="1" dy="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.141176 0 0 0 0 0.0627451 0 0 0 0 0.247059 0 0 0 1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
        </defs>
      </svg>

      {children}

      <svg className="up-right" width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
          <path d="M1.99994 2H-6.10352e-05V6H1.99994V8H5.99994V6H7.99994V4V2H5.99994V0H3.99994H1.99994V2Z" fill="#CA9219" />
        </g>
        <defs>
          <filter id="filter0_d" x="-6.10352e-05" y="0" width="9" height="9" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
            <feOffset dx="1" dy="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.141176 0 0 0 0 0.0627451 0 0 0 0 0.247059 0 0 0 1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
        </defs>
      </svg>

      <svg className="down-right" width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
          <path d="M1.99994 2H-6.10352e-05V6H1.99994V8H5.99994V6H7.99994V4V2H5.99994V0H3.99994H1.99994V2Z" fill="#CA9219" />
        </g>
        <defs>
          <filter id="filter0_d" x="-6.10352e-05" y="0" width="9" height="9" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
            <feOffset dx="1" dy="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0.141176 0 0 0 0 0.0627451 0 0 0 0 0.247059 0 0 0 1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
        </defs>
      </svg>
    </MyButton>
  )
}