import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';
import React from 'react';
import { CustomThemeProvider } from './context/ThemeProvider';
import { ArtworkProvider } from './context/ArtworkProvider';
import { FloorProvider } from './context/FloorProvider';

import Home from "./pages/Home";
import Notebook1 from "./pages/Notebook/1"
import Notebook2 from "./pages/Notebook/2"
import Notebook3 from "./pages/Notebook/3"
import Notebook4 from "./pages/Notebook/4"
import Suitcase from "./pages/Suitcase"
import OpenedSuitcase from "./pages/Suitcase/OpenedSuitcase"
import Shh from "./pages/Shh"
import Play from "./pages/Play"
import ConArtist from "./pages/ConArtist"
import StakeLpUnlocked from "./pages/ConArtist/StakeLpUnlocked"
import StackeTicketUsd from "./pages/ConArtist/StackeTicketUsd"
import DenOfThieves from './pages/DenOfThieves';
import ScamOMatic from './pages/ScamOMatic';
import TheLouvre from './pages/TheLouvre';
import Artworks from './pages/Suitcase/Artworks';
import { useMediaQuery } from 'react-responsive';
import Mobile from "./pages/Mobile"
import RobAChance from "./pages/TheLouvre/RobAChance";
import { storeWrapper } from "./components/store"
import artworkData from './services/mockedArtwork.json';

import ChoiceFarm from './pages/ConArtist/ChoiceFarm'
import GlobalStyle from './styles/global';
import FloorOne from './pages/TheLouvre/Floors/Floor/One';
import FloorTwo from './pages/TheLouvre/Floors/Floor/Two';
import FloorThree from './pages/TheLouvre/Floors/Floor/Three';
import FloorFour from './pages/TheLouvre/Floors/Floor/Four';
import FloorFive from './pages/TheLouvre/Floors/Floor/Five';
import FloorSix from './pages/TheLouvre/Floors/Floor/Six';
import FloorSeven from './pages/TheLouvre/Floors/Floor/Seven';
import useLocalStorage from './components/LocalStorage/useLocalStorage';
import { number } from 'yargs';


function App() {
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-width: 720px)'
});

  
  return (
    <CustomThemeProvider>
      <FloorProvider>
        <ArtworkProvider>
          <BrowserRouter>
            {!isTabletOrMobileDevice && (
                        <Switch>
                        <Route path="/" exact component={Play} />
                        <Route path="/notebook/1" exact component={Notebook1} />
                        <Route path="/notebook/2" exact component={Notebook2} />
                        <Route path="/notebook/3" exact component={Notebook3} />
                        <Route path="/notebook/4" exact component={Notebook4} />
                        <Route path="/suitcase" exact component={Suitcase} />
                        <Route path="/suitcase/open" exact component={OpenedSuitcase} />
                        <Route path="/suitcase/artworks" exact component={Artworks} />
                        <Route path="/shh" exact component={Shh} />
                        <Route path="/play" exact component={Play} />
                        <Route path="/conartist" exact component={ConArtist} />
                        <Route path="/conartist/choicefarm" exact component={ChoiceFarm} />
                        <Route path="/conartist/stakelpunlocked" exact component={StakeLpUnlocked} />
                        <Route path="/conartist/stacketicketusd" exact component={StackeTicketUsd} />
                        <Route path="/denofthieves" exact component={DenOfThieves} />
                        <Route path="/scamomatic" exact component={ScamOMatic} />
                        <Route path="/thelouvre" exact component={TheLouvre} />
                        <Route path="/floor/1" exact component={FloorOne} />
                        <Route path="/floor/2" exact component={FloorTwo} />
                        <Route path="/floor/3" exact component={FloorThree} />
                        <Route path="/floor/4" exact component={FloorFour} />
                        <Route path="/floor/5" exact component={FloorFive} />
                        <Route path="/floor/6" exact component={FloorSix} />
                        <Route path="/floor/7" exact component={FloorSeven} />
                        {artworkData.map(artwork => (<Route path={`/thelouvre/steal/:id`} exact component={RobAChance}/> ))}
                      </Switch>
            )}
            <GlobalStyle />
            {isTabletOrMobileDevice && <Mobile />}
          </BrowserRouter>
        </ArtworkProvider>
      </FloorProvider>
    </CustomThemeProvider>

  );
}

export default storeWrapper.withRedux(App)
