export const LPStaking = "0x9E88357451395F2a5d9E207BBB6C8d062f23b74e"

export const MockLP = "0xf89f5d7196e0bef64daffeb038300d3a4f6d34a8"

export const PriceLP = "0xf89f5d7196e0bef64daffeb038300d3a4f6d34a8"

export const USDC = "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174"

export const Ticket = "0x0514C869D3E0f553Edf590093914Abe91D9D0611"

export const TokenSale = "0x41f3D600D8Be3Cc7a538638e0685474343d37Fd5"

export const LockedLPStaking = "0xeD999d9E38bC531bd69FF3a1C5CE7E4E1aA3f1aE" 

export const LART = "0xE19890FE4a2D224bAa3AAB45a9A2853c32CE7a2F"

export const Louvre = "0xB7E5D33106E8906B4b394725B225552BF3A9Ebb8"