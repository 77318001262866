import styled from "styled-components";
import frameBadge from '../../assets/frameBadge.svg'

export const Container = styled.div`
   background: url(${frameBadge}) no-repeat center; 
   font-size: 24px;
   color: ${({ theme }) => theme.colors.gold100};
   text-shadow: -1px -1px 0px ${({ theme }) => theme.colors.darkblue100};
   width: 100%;
   height: 32px;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: default;
   position: absolute;

`;
