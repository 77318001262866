import styled from 'styled-components';
import ConArtistDefaut from '../../assets/buttons/defaut/ConArtist.svg';
import MarketDefaut from '../../assets/buttons/defaut/Market.svg';
import ScamOMaricDefaut from '../../assets/buttons/defaut/ScamOMatic.svg'
import ExibitionsDefaut from '../../assets/buttons/defaut/Exibitions.svg'
import ConArtistPressed from '../../assets/buttons/pressed/ConArtist.svg';
import MarketPressed from '../../assets/buttons/pressed/Market.svg';
import ScamOMaricPressed from '../../assets/buttons/pressed/ScamOMatic.svg'
import ExibitionsPressed from '../../assets/buttons/pressed/Exibitions.svg'
import Twitter from  '../../assets/Twitter.svg'
import Telegram from  '../../assets/Telegram.svg'
import Discord from  '../../assets/Discord.svg'



export const Container = styled.div`
    max-width: 100vw !important;
`;


export const FrameContent = styled.div`
    height: 93%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;
`;

export const ConArtistButton = styled.div`
   background: url(${ConArtistDefaut}) no-repeat center; 
   position: relative;
    width: 175px;
    height: 50px;
    left: calc(50% - 175px/2 + 62.5px);
    top: calc(50% - 50px/2 + 91px);
    cursor: pointer;

   :active {
      background: url(${ConArtistPressed});
   }
`;

export const DenOfThievesButton = styled.div`
   background: url(${MarketDefaut}) no-repeat center; 
   position: relative;
    width: 280px;
    height: 50px;
    left: calc(50% - 238px/2 + 236px);
    top: calc(50% - 50px/2 + 227px);
    cursor: pointer;

   :active {
      background: url(${MarketPressed});
   }
    

`;

export const ScamOMaricButton = styled.div`
   background: url(${ScamOMaricDefaut}) no-repeat center; 
   position: relative;
    width: 250px;
    height: 50px;
    left: calc(50% - 206px/2 + 35px);
    top: calc(50% - 50px/2 + 373px);
    cursor: pointer;

   :active {
      background: url(${ScamOMaricPressed});
   }
`;

export const TheLouvreButton = styled.div`
   background: url(${ExibitionsDefaut}) no-repeat center; 
   position: relative;
    width: 280px;
    height: 52px;
    left: calc(50% - 238px/2 - 156px);
    top: calc(50% - 50px/2 + 180px);
    cursor: pointer;

   :active {
      background: url(${ExibitionsPressed});
   }
   
`;

export const Welcome = styled.span`
    position: relative;
    width: 472px;
    height: 36px;
    left: calc(50% - 172px/2 - 22px);
    top: calc(50% - 36px/2 - 126px);
    /* Fanda Egyptian */

    font-family: Fanda Egyptian;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 36px;
    /* identical to box height, or 112% */

    display: flex;
    align-items: center;
    text-align: right;

    color: #FFFFFF;

    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

`;
export const Louvre = styled.h3`
    position: relative;
    width: 432px;
    height: 62px;
    left: calc(50% - 432px/2 + 154px);
    top: calc(50% - 99px/2 - 89px);

    font-family: Alagard;
    font-style: normal;
    font-weight: 500;
    font-size: 60px;
    line-height: 62px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-align: right;

    /* Golden */

    color: #F8D14A
    ;

    text-shadow: 0px 4px 0px #24103F;
`;

export const Twittere = styled.div`
   background: url(${Twitter}) no-repeat center; 
   position: relative;
   width: 43.33px;
   height: 38px;
   left: calc(50% + 543.33px/2);
   top: calc(50% - 455px/2);


   /* Inside Auto Layout */

   flex: none;
   order: 0;
   flex-grow: 0;
   margin: 0px 7px;
    cursor: pointer;
`

export const Telegrame = styled.div`
   background: url(${Telegram}) no-repeat center; 
   position: relative;
   width: 43.33px;
   height: 39px;
   left: calc(50% + 663.33px/2);
   top: calc(50% - 529px/2);


   /* Inside Auto Layout */

   flex: none;
   order: 0;
   flex-grow: 0;
   margin: 0px 7px;
    cursor: pointer;
`
export const Discorde = styled.div`
   background: url(${Discord}) no-repeat center; 
   position: relative;
   width: 49.33px;
   height: 39px;
   left: calc(50% + 783.33px/2);
   top: calc(50% - 609px/2);


   /* Inside Auto Layout */

   flex: none;
   order: 0;
   flex-grow: 0;
   margin: 0px 7px;
    cursor: pointer;
`