import { ConArtistButton, Container, DenOfThievesButton, FrameContent, ScamOMaricButton, TheLouvreButton, Welcome, Louvre, Twittere, Telegrame, Discorde } from './styles'
import Frame from '../../components/Frame';
import Navbar from '../../components/Navbar';
import FrameBadge from '../../components/FrameBadge';
import MarketInfo from '../../components/MarketInfo';
import { useHistory } from 'react-router-dom';
import { Scroll } from '../../components/Scroll';




export default function Play() {
    const history = useHistory();

    return (
        <Container>
            <Navbar />
            <Frame data='Play'>
                <div>
                <Scroll>
                    <p>The Plot</p><small>ready</small>
                </Scroll>
                </div>
                <FrameContent>
                    <div>
                        {/* <ConArtistButton  onClick={() => history.push('/conartist')}></ConArtistButton> */}
                    </div>
                    <div>
                        <DenOfThievesButton onClick={() => history.push('/conartist')}></DenOfThievesButton>
                    </div>
                    {/* <div>
                        <ScamOMaricButton onClick={() => history.push('/scamomatic')}></ScamOMaricButton>
                    </div> */}
                    <div>
                        <TheLouvreButton onClick={() => history.push('/thelouvre')}></TheLouvreButton>
                    </div>
                    <div>
                        <Welcome>Welcome to</Welcome>
                        <Louvre>Louvre Finance</Louvre>
                        <Welcome>Steal Metaverse in Fantom</Welcome>
                    </div>
                    <div >
                        <Twittere onClick={() => { 
                        window.open('https://twitter.com/LouvreFinance', '_blank');}}></Twittere>
                    </div>
                    <div>
                        <Telegrame onClick={() => { 
                        window.open('https://t.me/louvrefinancedefi', '_blank');}}></Telegrame>
                    </div>
                    <div>
                        <Discorde onClick={() => { 
                        window.open('https://discord.gg/Jg4EpVa6', '_blank');}}></Discorde>
                    </div>
                </FrameContent>
                <FrameBadge>
                    Town
                </FrameBadge>

            </Frame>

            <MarketInfo />
        </Container>
    )
}