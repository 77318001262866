import styled from 'styled-components';

export const Container = styled.div`
    max-width: 100vw !important;
`;


export const FrameContent = styled.div`
    height: 93%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;
`;