import React from 'react'
import BigNumber from 'bn.js'
import { AbiItem } from "web3-utils"
import useLocalStorage from '../LocalStorage/useLocalStorage'
import useConnect from './useConnect'
import { BrowserRouter as Router, Link, Switch, Route } from "react-router-dom";


import { ToastContainer, toast, } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import toastImg from '../../assets/Toast.svg'


import web3 from '../utils/web3'
import PriceLP from "../constants/abi/PriceLP.json"
import { any } from 'glamor'

const usePriceLP = () => {
    const { Message } = useConnect()

    const [userWalletAddress, setUserWalletAddress] = useLocalStorage('userAddress', '')

    const getPriceLP = (address: string) => {
        const contract = new web3.eth.Contract((PriceLP.abi as unknown) as AbiItem, address)
        return contract
    }

//maxPriorityFeePerGas: null, maxFeePerGas: null 1,500000031  [0] some((element: string) => element === 'PurchaseExecuted')  gas: 270804, gasPrice: 1500000031 
    const viewgetReserves = async (addressPriceLP: string) => {
        const contract = await getPriceLP(addressPriceLP)
        const value = await contract.methods.getReserves().call({ from: userWalletAddress })
        return (value)
    }
    
    return { 
        getPriceLP,
        viewgetReserves,
      }
}

export default usePriceLP