import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: Nail Scratch;
    font-style: normal;
    font-weight: normal;
    height: 100%;
    position: absolute;
    top: -50px;
    left: -180px;
    background-color: rgba(0,0,0,0.25);
    width: 100%;

    h1 {
        font-size: 120px;
        color: #CC2D2D;
        transform: rotate(-15deg);
        position: absolute;
        left: 220px;
        top: 100px;
    }

    h2 {
        font-size: 50px;
        color: #fff;
        transform: rotate(-10deg);
        position: absolute;
        left: 400px;
        top: 310px;
    }
`;