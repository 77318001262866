import styled from "styled-components";
import ArtworkDefaultButton from '../../../../assets/ArtworkDefaultButton.svg';
import ArtworkPressedButton from '../../../../assets/ArtworkPressedButton.svg';


export const ButtonContainer = styled.button`
   background: url(${ArtworkDefaultButton}) no-repeat;
   border: 0;
   outline: 0;
   color: #fff;
   margin-left: 40px;
   margin-top: 15px;
   width: 200px;
   height: 50px;
   cursor: pointer;
   font-family: Fanda Egyptian;
   font-size: 24px;
   color: ${({ theme }) => theme.colors.white};

   :active {
      background: url(${ArtworkPressedButton});
   }
   
`;
