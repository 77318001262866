import styled from 'styled-components';
import { ArtworkScroll } from '../../../components/ArtworkScroll';

export const Container = styled.div`
    max-width: 100vw !important;
`;


export const FrameContent = styled.div`
    height: 93%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const OpenedArtworkScroll = styled(ArtworkScroll)`
`