import { Container, BackGreenButton, BackOrangeButton, FrameContent, NextPage, PrevewsPage, TextOne, TextTwo, TitleOne, TitleTwo } from './styles'
import Button from '../../../components/Button';
import Frame from '../../../components/Frame';
import Navbar from '../../../components/Navbar';
import FrameBadge from '../../../components/FrameBadge';
import MarketInfo from '../../../components/MarketInfo';
import BlueButton from '../ButtonNotebook/Blue'
import { useHistory } from 'react-router-dom';
import RedButton from '../ButtonNotebook/Red';
import OrangeButton from '../ButtonNotebook/Orange';





export default function Notebook() {

    const history = useHistory();

    
    return (
        <Container> 
            <Navbar/>
            <Frame data='Notebook3'>
            
                <FrameContent >
                    <div>
                    <TitleOne>Scam-o-matic</TitleOne>
                    </div>
                    <div>
                    <TextOne>This is a shitty TICKET machine that the museum forgot to turn off. It barely works and you can be sure that that it is a scam. <br />
                    <br />
                    Once in a while you can get a transaction throug. You get a TICKET and the money goes to the devs. <br />
                    <br />
                    There is only one Scam-o-matic and the thing is quite slow. After some tests It seems to be able to emit a TICKET only once every 30 seconds. 
                    </TextOne>
                    </div>

                    <div>
                        <TitleTwo>
                        </TitleTwo>
                    </div>
                    <div>
                        <TextTwo>
                        Without the Con Artist it is the only way to get tickets so you may find the headache worth it. <br />
                        <br />
                        Looks like I'll have stand in front of that machine for a while. Cheers! ...Nothing else to add about it
                        </TextTwo>
                    </div>
                    <div>
                    </div>
                    <div onClick={() => history.push('/notebook/4')}>
                        <RedButton></RedButton>
                    </div>
                    <div>
                    </div>
                    <div onClick={() => history.push('/notebook/4')}> 
                        <NextPage>next page -{'>'}</NextPage>
                    </div>
                    <div onClick={() => history.push('/notebook/2')}>
                        <PrevewsPage>{'<'}- previus page</PrevewsPage>
                    </div>
                    <div onClick={() => history.push('/notebook/1')}>
                        <BackGreenButton></BackGreenButton>
                    </div>
                    <div onClick={() => history.push('/notebook/2')}>
                        <BackOrangeButton></BackOrangeButton>
                    </div>
                                    
                </FrameContent>
                <FrameBadge>
                    Plans for the Heist
                </FrameBadge>

            </Frame>

            <MarketInfo />
        </Container>
    )
}