export const lightTheme = {
    name: 'light',
    colors: {
        gold30: '#FBFFD0',
        gold80: '#F8D14A',
        gold100: '#CA9219',
        brown100: '#875638',
        white: '#FFF',
        red20:'#6B2434',
        red40: '#631E1C',
        darkblue100: '#24103F',
        green100: '#173D36',
        shadow: '#F8D14A',
        black: '#05070A'
    }
}
