import { ScrollContainer } from './styles';


export function ArtworksScroll(props: any) {
    return (
        <>
            <ScrollContainer>
                {props.children}
            </ScrollContainer>
        </>
    )
}