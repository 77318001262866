import { createContext, useCallback, useState } from "react";
import { IArtwork } from "./ArtworkProvider";
import Floors from '../services/Floors.json';

export interface IFloor {
    id: number;
    Artworks: IArtwork[];
    Objects: IObjects[];
    SecurityGuards: ISecurityGuards[];
    Color: string;
  };
  
export interface IObjects {
id: number;
imgURL: string;
}

export interface ISecurityGuards {
id: number;
imgURL: string;
}

interface FloorContextData { floor: IFloor, getFloor(flooorNumber: number): void | IFloor};

export const FloorContext = createContext<FloorContextData>(
    {} as FloorContextData
);

export const FloorProvider: React.FC = ({ children }) => {
  const [floor, setFloor] = useState<IFloor>(Floors[0]);

  const getFloor = useCallback((floorNumber: number) => {
    const FloorData:any | IFloor = Floors.find((Floor: IFloor): boolean => Floor.Artworks[0].Floor === floorNumber);

    setFloor(FloorData);

  }, []);


  return (
    <FloorContext.Provider value={{ floor, getFloor }}>
        {children}
    </FloorContext.Provider>
  );
};