import React from 'react'
import BigNumber from 'bn.js'
import { AbiItem } from "web3-utils"
import useLocalStorage from '../LocalStorage/useLocalStorage'
import useConnect from './useConnect'
import { BrowserRouter as Router, Link, Switch, Route } from "react-router-dom";


import { ToastContainer, toast, } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import toastImg from '../../assets/Toast.svg'
import { Ticket } from '../constants/tokenAddresses'
import { BNtoDecimal } from '../utils/numerals'

import web3 from '../utils/web3'
import web3event from '../utils/web3event'
import Louvre from "../constants/abi/Louvre.json"
import useBalance from './useBalance'
import { toBlock } from '@babel/types'

declare let window: any;


const useLouvre = () => {
    const { Message } = useConnect()
    const { getBalanceToken } = useBalance()


    const [userWalletAddress, setUserWalletAddress] = useLocalStorage('userAddress', '')
    const [timeMachine, setTimeMachine] = useLocalStorage('timeMachine', 0)
    const [concludMachine, setConcludMachine] = useLocalStorage('concludMachine', false)
    const [time, setTime] = useLocalStorage('time', 0)


    const getLouvre = (address: string) => {
        const contract = new web3.eth.Contract((Louvre.abi as unknown) as AbiItem, address)
        return contract
    }

    const getLouvreWss = (address: string) => {
        const contract = new web3event.eth.Contract((Louvre.abi as unknown) as AbiItem, address)
        return contract
    }

    const initializeAttempt = async (addressLouvre: string, nftID: Number, hired: Number) => {
        const contract = await getLouvre(addressLouvre)
        const contractevent = await getLouvreWss(addressLouvre)
        const BlockNumber = await Promise.resolve(web3event.eth.getBlockNumber())
        await Promise.resolve(contractevent.events.RobberyFinalized({
            toBlock: 'latest',
            fromBlock: BlockNumber
        }, function(error: any, event: any){}
        ).on('data', function(event: any){
            // console.log('finalized')
            if (event.returnValues.requestId === window.requestIdRecipt) {
                setTimeMachine(2)
                if(event.returnValues._bool === true){
                    setConcludMachine(true)
                    Message([{head : "Congratulations", body: `You managed to steal nft with id: ${event.returnValues._nftId}, check your switcasewait, Loser`, variant: 'success'}])
                } else {
                    setConcludMachine(false)
                    Message([{head : "Try again", body: `You failed to steal nft with id: ${event.returnValues._nftId}, Loser`, variant: 'warning'}])
                }
            } else {
                if (event.returnValues._userAddress === userWalletAddress){
                if(event.returnValues._bool === true){
                    Message([{head : "Congratulations", body: `You managed to steal nft with id: ${event.returnValues._nftId}, check your switcasewait, Loser`, variant: 'success'}])
                } else {
                    Message([{head : "Try again", body: `You failed to steal nft with id: ${event.returnValues._nftId}, Loser`, variant: 'warning'}])
                }
            }
            }
        }))
        return await contract.methods.initializeAttempt(nftID, hired).send({ from: userWalletAddress, gas: 396037, gasPrice: 50000000031  })
        .once('transactionHash', function(hash: any){
            Message([{head: 'Transaction Sent', body: <a onClick= {() => {window.open(`https://polygonscan.com/tx/${hash}`, '_blank')}}>See-on-PolygonSCAM</a> , variant:'info'}])
        })
        .once('receipt', function(receipt: any){
                window.requestIdRecipt = receipt.events.RobberyInitialized.returnValues.requestId
                setTime(Date.now())
                setTimeMachine(1)
                Message([{head : "Congratulations", body: "You're attempting to steal Louvre, this process may take a few minutes... Loser", variant: 'success'}])
                
        }
        )
        }

    const claimReward = async (addressLouvre: string, claimRewardNumber: number) => {
        const contract = await getLouvre(addressLouvre)
        return await contract.methods.claimReward(claimRewardNumber).send({ from: userWalletAddress, maxPriorityFeePerGas: null, maxFeePerGas: null })
        .once('transactionHash', function(hash: any){
            Message([{head: 'Transaction Sent', body: <a onClick= {() => {window.open(`https://polygonscan.com/tx/${hash}`, '_blank')}}>See-on-PolygonSCAM</a> , variant:'info'}])
        })
        .once('receipt', function(receipt: any){
            console.log(receipt)
                Message([{head : "Congratulations", body: "We never did business, you don't know me", variant: 'success'}])
        })
        }


//maxPriorityFeePerGas: null, maxFeePerGas: null 1,500000031  [0] some((element: string) => element === 'PurchaseExecuted')  gas: 270804, gasPrice: 1500000031 
    const viewNumberOfAttempts = async (addressLouvre: string, nftId: number) => {
        const contract = await getLouvreWss(addressLouvre)
        const value = await contract.methods.viewNumberOfAttempts(nftId).call({ from: userWalletAddress })
        return (value)
    }
    
    const viewNumberOfSucesses = async (addressLouvre: string, nftId: number) => {
        const contract = await getLouvreWss(addressLouvre)
        const value = await contract.methods.viewNumberOfSucesses(nftId).call({ from: userWalletAddress })
        return (value)
    }

    const viewProbability = async (addressLouvre: string, nftId: number, hired: number) => {
        const contract = await getLouvreWss(addressLouvre)
        const value = await contract.methods.finalProbability(nftId, hired).call({ from: userWalletAddress })
        return (value)
    }

    const viewReward = (addressLouvre: string, nftId: number) => {
        const contract = getLouvreWss(addressLouvre)
        return (contract.methods.viewReward(nftId).call({ from: userWalletAddress }))
    }

    const viewNFTStatus = (addressLouvre: string, nftId: number) => {
        const contract = getLouvreWss(addressLouvre)
        return (contract.methods.viewNFTStatus(nftId).call({ from: userWalletAddress }))
    }

    const multiplierFee = (addressLouvre: string, hired: number) => {
        const contract = getLouvreWss(addressLouvre)
        return (contract.methods.multiplierFee(hired).call({ from: userWalletAddress }))
    }


    return { 
        getLouvre,
        getLouvreWss,
        initializeAttempt,
        claimReward,
        viewNumberOfAttempts,
        viewNumberOfSucesses,
        viewProbability,
        viewReward,
        viewNFTStatus,
        multiplierFee
      }
}

export default useLouvre