import styled from "styled-components";
import defaut from '../../../assets/buttons/defaut/openSuitcase.svg';
import pressed from '../../../assets/buttons/pressed/openSuitcase.svg';

export const Background = styled.div`
   background: url(${defaut}); 
   position: relative;
   width: 272px;
   height: 50px;
   left: calc(50% - 272px/2);
   top: calc(50% - 50px/2 + 123px);
   cursor: pointer;

   :active {
      background: url(${pressed});
   }
   
`;
