import styled from 'styled-components';

export const Container = styled.div`
    max-width: 100vw !important;
`;


export const FrameContent = styled.div`
    height: 93%;
    display: flex;
    justify-content: center;
    padding-top: 70px;
    

    p {
        margin-bottom: 25px;
        margin-top: 65px;
    }

    small {
        margin: 15px 0 15px 30px;
    }
`;

export const ArtworkContainer = styled.div`
    margin-right: 450px;
`;

export const ArtworkContent = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 480px;

    span {
        color: #fff;
        font-size: 18px;
        margin: 10px 0;
    }
`;