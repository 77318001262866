import styled from 'styled-components';

export const Title = styled.h1`
position: relative;
width: 270px;
height: 24px;
left: 0px;
top: -20px;

/* Fanda Eyptian 24 */

font-family: Fanda Egyptian;
font-style: normal;
font-weight: normal;
font-size: 24px;
line-height: 24px;
/* identical to box height */


/* Red */

color: #6B2434;


/* Inside Auto Layout */

flex: none;
order: 0;
flex-grow: 0;
margin: 0px 3px;
`

export const Text = styled.a`

position: relative;
width: 474px;
height: 69px;
left: 0px;
top: 0px;

/* Fanda Egyptian 18 */

font-family: Fanda Egyptian;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 18px;

/* Shadow */

color: #24103F;


/* Inside Auto Layout */

flex: none;
order: 1;
flex-grow: 0;
margin: 3px 0px;
`