import styled from "styled-components";


export const Container = styled.div`
margin: 0 auto;
max-width: 1280px;
display: flex;
justify-content: space-around;

strong {
    color: ${({ theme }) => theme.colors.red40};
    text-shadow: none;
}
span {
    color: #173D36;
    text-shadow: none;
}
`;