import styled from 'styled-components';
import BackwardButton from '../../../../../assets/BackwardButton.svg';
import ForwardButton from '../../../../../assets/ForwardButton.svg';
import ForwardPressedButton from '../../../../../assets/ForwardPressedButton.svg';
import BackwardPressedButton from '../../../../../assets/BackwardPressedButton.svg';
import Backdefaut from '../../../../../assets/buttons/defaut/Back.svg';
import Backpressed from '../../../../../assets/buttons/pressed/Back.svg';





export const FrameContent = styled.div`
    height: 93%;
    padding-top: 70px;
    max-width: 100%;
    z-index: 1;

    p {
        position: relative;
        margin-bottom: 5px;
        margin-top: 45px;
        font-size: 24px;
        max-width: 80%;
    }

    small {
        margin: 15px 0 10px 30px;
        max-width: 100%;
        margin-bottom: 20px;
    }

    span {
        position: relative;
        margin-left: 30px;
        max-width: 80%;
        margin-bottom: 25px;


    }

    .swiper-container {
        max-width: 946px;
        height: 538px;
        cursor: pointer;
    }

    .swiper-button-prev {
        background: url(${BackwardButton}) no-repeat;
        border: 0;
        position: relative;
        width: 64px;
        height: 199px;
        left: calc(50% - 64px/2 - 604px);
        top: -335px;
        cursor: pointer;
        z-index: 100;
        :active {
            background: url(${BackwardPressedButton});
        }
        :after, .swiper-container-rtl .swiper-button-prev::after {
            content: '';
        }
    }

    .swiper-button-next {
        background: url(${ForwardButton}) no-repeat;
        border: 0;
        position: relative;
        width: 64px;
        height: 199px;
        left: calc(50% - 64px/2 + 608px);
        top: -515px;
        cursor: pointer;
        z-index: 100;
        :active {
            background: url(${ForwardPressedButton});
        }
        :after, .swiper-container-rtl .swiper-button-prev::after {
            content: '';
        }
    }
    
`;

export const ArtworkContainer = styled.div`
    height: 100%;
    position: relative;
    left: calc(50% - 220px/2 - 180px);
    top: calc(50% - 118px/2 - 158px);
    justify-content: center;
    align-items: center;
    
    img.art {
        width: 338px;
        height: 470px;
        justify-content: center;
    }
    button- {
        z-index: 1000;
    }
`;

export const ElementContainer = styled.div`
    position: absolute;
    left: calc(50% - 220px/2 - 540px);
    top: calc(50% - 118px/2 - 88px);
    justify-content: center;
    align-items: center;

`;

export const Back = styled.div`
   background: url(${Backdefaut}); 
    position: relative;
    width: 67px;
    height: 50px;
    left: calc(50% - 50px/2 - 320px);
    top: +90px;
    cursor: pointer;

    :active {
      background: url(${Backpressed});
   }
   
`; 

export const Back1 = styled.div`
   background: url(${Backdefaut}); 
    position: relative;
    width: 67px;
    height: 50px;
    left: calc(50% - 50px/2 - 520px);
    top: -530px;
    cursor: pointer;

    :active {
      background: url(${Backpressed});
   }
   
`; 