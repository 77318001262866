import { Container, BackOrangeButton, ExitBlue, FrameContent, NextPage, PrevewsPage, TextOne, TextTwo, TitleOne, TitleTwo, BackBleuButto, BackGreenButton } from './styles'
import Button from '../../../components/Button';
import Frame from '../../../components/Frame';
import Navbar from '../../../components/Navbar';
import FrameBadge from '../../../components/FrameBadge';
import MarketInfo from '../../../components/MarketInfo';
import BlueButton from '../ButtonNotebook/Blue'
import { useHistory } from 'react-router-dom';
import RedButton from '../ButtonNotebook/Red';
import OrangeButton from '../ButtonNotebook/Orange';





export default function Notebook() {

    const history = useHistory();

    
    return (
        <Container> 
            <Navbar/>
            <Frame data='Notebook4'>
            
                <FrameContent >
                    <div>
                    <TitleOne>The Den of Thieves </TitleOne>
                    </div>
                    <div>
                    <TextOne>I'm sure that hiring 100 men with some incentive will make robbing those pieces be a lot easyer. <br />
                    <br />
                     Now that I have a plan for the Heist, now is only me. I'll go to the den</TextOne>
                    </div>
                    <div>
                        <TitleTwo>
                        </TitleTwo>
                    </div>
                    <div>
                        <TextTwo>
                        
                        </TextTwo>
                    </div>
                    <div>
                    </div>
                    <div>
                    </div>
                    <div>
                    </div>
                    <div>
                    </div>
                    <div onClick={() => history.push('/notebook/3')}>
                        <PrevewsPage>{'<'}- previus page</PrevewsPage>
                    </div>
                    <div onClick={() => history.push('/notebook/1')}>
                        <BackGreenButton></BackGreenButton>
                    </div>
                    <div onClick={() => history.push('/notebook/2')}>
                        <BackOrangeButton></BackOrangeButton>
                    </div>
                    <div onClick={() => history.push('/notebook/3')}>
                        <BackBleuButto></BackBleuButto>
                    </div>
                </FrameContent>
                <FrameBadge>
                    Plans for the Heist
                </FrameBadge>

            </Frame>

            <MarketInfo />
        </Container>
    )
}