import styled from "styled-components";
import marketStats from '../../../assets/buttons/marketStats.svg';

export const Background = styled.div`
   background: url(${marketStats}) no-repeat center; 
   color: ${({ theme }) => theme.colors.gold100};
   text-shadow: -1px -1px 0px ${({ theme }) => theme.colors.darkblue100};
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   cursor: default;
   width: 300px;
   height: 100px;
   font-size: 22px;
   transition: opacity, 1s;
   text-align: center;
`;
