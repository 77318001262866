import React from 'react'
import BigNumber from 'bn.js'
import { AbiItem } from "web3-utils"
import useLocalStorage from '../LocalStorage/useLocalStorage'
import useConnect from './useConnect'
import { BrowserRouter as Router, Link, Switch, Route } from "react-router-dom";


import { ToastContainer, toast, } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import toastImg from '../../assets/Toast.svg'


import web3 from '../utils/web3'
import web3event from '../utils/web3event'

import LPStaking from "../constants/abi/LPStaking.json"
import { any } from 'glamor'

const useLPStackinf = () => {
    const { Message } = useConnect()

    const [userWalletAddress, setUserWalletAddress] = useLocalStorage('userAddress', '')

    const getLPSContract = (address: string) => {
        const contract = new web3.eth.Contract((LPStaking.abi as unknown) as AbiItem, address)
        return contract
    }

    const getLPSContractWss = (address: string) => {
        const contract = new web3event.eth.Contract((LPStaking.abi as unknown) as AbiItem, address)
        return contract
    }

    const stake = async (addressLLPstacking: string, stakeNumber: BigNumber) => {
        const contract = await getLPSContract(addressLLPstacking)
        return await contract.methods.stake(stakeNumber).send({ from: userWalletAddress, maxPriorityFeePerGas: null, maxFeePerGas: null })
        .once('transactionHash', function(hash: any){
            Message([{head: 'Transaction Sent', body: <a onClick= {() => {window.open(`https://polygonscan.com/tx/${hash}`, '_blank')}}>See-on-PolygonSCAM</a> , variant:'info'}])
        })
        .once('receipt', function(receipt: any){
            console.log(receipt)
                Message([{head : "Congratulations", body: 'Tanks for the cash, Loser', variant: 'success'}])
        }
        )
        }

    const withdraw = async (addressLLPstacking: string, withdrawNumber: BigNumber) => {
        const contract = await getLPSContract(addressLLPstacking)
        return await contract.methods.withdraw(withdrawNumber).send({ from: userWalletAddress, maxPriorityFeePerGas: null, maxFeePerGas: null })
        .once('transactionHash', function(hash: any){
            Message([{head: 'Transaction Sent', body: <a onClick= {() => {window.open(`https://polygonscan.com/tx/${hash}`, '_blank')}}>See-on-PolygonSCAM</a> , variant:'info'}])
        })
        .once('receipt', function(receipt: any){
            console.log(receipt)
                Message([{head : "Congratulations", body: "We never did business, you don't know me", variant: 'success'}])
        }
        )
        }

    const getReward = async (addressLLPstacking: string) => {
        const contract = await getLPSContract(addressLLPstacking)
        return await contract.methods.getReward().send({ from: userWalletAddress, maxPriorityFeePerGas: null, maxFeePerGas: null })
        .once('transactionHash', function(hash: any){
            Message([{head: 'Transaction Sent', body: <a onClick= {() => {window.open(`https://polygonscan.com/tx/${hash}`, '_blank')}}>See-on-PolygonSCAM</a> , variant:'info'}])
        })
        .once('receipt', function(receipt: any){
            console.log(receipt)
                Message([{head : "Congratulations", body: "We never did business, you don't know me", variant: 'success'}])
        }
        )
        }
//maxPriorityFeePerGas: null, maxFeePerGas: null 1,500000031  [0] some((element: string) => element === 'PurchaseExecuted')  gas: 270804, gasPrice: 1500000031 
    const viewtotalSupply = async (addressLLPstacking: string) => {
        const contract = await getLPSContractWss(addressLLPstacking)
        const value = await contract.methods.totalSupply().call({ from: userWalletAddress })
        return (value)
    }
    
    const viewbalanceOf = async (addressLLPstacking: string) => {
        const contract = await getLPSContractWss(addressLLPstacking)
        const value = await contract.methods.balanceOf(userWalletAddress).call({ from: userWalletAddress })
        return (value)
    }

    const viewperiodFinish = async (addressLLPstacking: string) => {
        const contract = await getLPSContractWss(addressLLPstacking)
        const value = await contract.methods.periodFinish().call({ from: userWalletAddress })
        return value
    }

    const viewearned = (addressLLPstacking: string) => {
        const contract = getLPSContractWss(addressLLPstacking)
        return (contract.methods.earned(userWalletAddress).call({ from: userWalletAddress }))
    }

    return { 
        getLPSContract,
        getLPSContractWss,
        stake,
        withdraw,
        viewtotalSupply,
        viewbalanceOf,
        viewperiodFinish,
        viewearned,
        getReward
      }
}

export default useLPStackinf