import { Container, FrameContent, Screen, TextOne, TextTwo, TextTree, TextFour, TextFive, TextSix, TwitterImg, MediumImg, TelegramImg, GithubImg, DiscordImg } from './styles'
import Frame from '../../components/Frame';
import Navbar from '../../components/Navbar';
import FrameBadge from '../../components/FrameBadge';
import MarketInfo from '../../components/MarketInfo';
import frameShh from '../../assets/frameShh.svg'

declare let window: any;


export default function Shh() {
    return (
        <Container> 
            <Navbar/>
            <div className="position-absolute top-0 start-0 translate-middle">
            <Frame>
                <FrameContent>
                    <Screen>
                        <TextOne>this project is a little bit anonymous...</TextOne>
                        <TextTwo onClick={() => { 
                        window.open('https://github.com/Louvre-Finance-Organization', '_blank');}}>Don't let athos know i <br /> shared the code <br />
                        <br />
                         athos is coming..</TextTwo>
                        <TextTree onClick={() => { 
                        window.open('https://discord.gg/Jg4EpVa6', '_blank');}}>All I see is <br />
                         discord all the <br />
                          time. gimme a break </TextTree>
                        <TextFour onClick={() => { 
                        window.open('https://medium.com/@louvre-finance', '_blank');}}>What a shitty...</TextFour>
                        <TextFive onClick={() => { 
                        window.open('https://t.me/louvrefinancedefi', '_blank');}}>Damn it stop sending <br />
                         me telegrams, I'll call <br />
                          my contractor  </TextFive>
                        <TextSix onClick={() => { 
                        window.open('https://twitter.com/LouvreFinance', '_blank');}}>Someone started <br />
                         following me... Maybe if i <br />
                          pretend I'm crazy they <br />
                           will go away</TextSix>
                        <TwitterImg onClick={() => { 
                        window.open('https://twitter.com/LouvreFinance', '_blank');}}></TwitterImg>
                        <MediumImg onClick={() => { 
                        window.open('https://medium.com/@louvre-finance', '_blank');}}></MediumImg>
                        <TelegramImg onClick={() => { 
                        window.open('https://t.me/louvrefinancedefi', '_blank');}}></TelegramImg>
                        <GithubImg onClick={() => { 
                        window.open('https://github.com/Louvre-Finance-Organization', '_blank');}}></GithubImg>
                        <DiscordImg onClick={() => { 
                        window.open('https://discord.gg/Jg4EpVa6', '_blank');}}></DiscordImg>
                    </Screen>
                </FrameContent>
                <FrameBadge>
                    keep it secret...
                </FrameBadge>

            </Frame>
            </div>
            <MarketInfo />
        </Container>
    )
}