import styled from "styled-components";
import openedScroll from '../../assets/Scroll/openedScroll.svg';


export const ScrollContainer = styled.div`
   background: url(${openedScroll}) no-repeat;
   position: absolute;
   width: 261px;
   height: 220px;
   top: 110px;
   left: auto;
   display: flex;
   cursor: pointer;
   font-family: Fanda Egyptian;
   justify-content: center;
   align-items: center;
   h1 {
      text-align: center;
      text-transform: uppercase;
      font-size: 18px;
      max-width: 150px;
      color: ${({ theme }) => theme.colors.red40};
   }
`;

export const ScrollTickets = styled.div`
   position: absolute;
   top: 300px;
   left: calc(50% - 220px/2 - 317px);
   font-size: 18px;
   font-family: Fanda Egyptian;
   color: ${({ theme }) => theme.colors.black};
`