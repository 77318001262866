import { Container, FrameContent, Title, TalkLeft, FarmTickets, CloseModalButton, Text, BuyTicket, StakeLpUnlocked, StakeLpLocked, Back, AddMetamask } from './styles'
import Frame from '../../components/Frame';
import Navbar from '../../components/Navbar';
import FrameBadge from '../../components/FrameBadge';
import MarketInfo from '../../components/MarketInfo';
import { useHistory } from 'react-router-dom';
import { Scroll } from '../../components/Scroll';
import Modal from 'react-modal'
import React from 'react'
import useConnect from '../../components/hooks/useConnect';




export default function ConArtist() {
    const history = useHistory();
    const [modalIsOpen, setIsOpen] = React.useState(false);

    const { AddCoin } = useConnect()


    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)'
          },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          background: 'rgba(0, 0, 0, 0.5)'
        },
      };

      function openModal() {
        setIsOpen(true);
      }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <Container>
            <Navbar />
            <Frame data='ConArtist'>
                <FrameContent>
                    <Scroll>
                        
                    </Scroll>
                    {/* <div>
                        <Title> $TICKET Market </Title>
                    </div>
                    <div>
                        <Text>TICKETS! TICKETS! <br />
                            <br />
                            Buy with me or lend me your TICKETS and I will get you some good money back.</Text>
                    </div>
                    <div >
                        <BuyTicket onClick={() => { 
                        window.open('https://quickswap.exchange/#/swap?outputCurrency=0x0514C869D3E0f553Edf590093914Abe91D9D0611', '_blank');}}></BuyTicket>
                    </div>
                    <div>
                        <FarmTickets onClick={() => history.push('/conartist/choicefarm')}></FarmTickets>
                    </div> */}
                    {/* <div>
                        <StakeLpUnlocked onClick={() => history.push('/conartist/stakelpunlocked')}></StakeLpUnlocked>
                    </div>
                    <div>
                        <StakeLpLocked onClick={() => history.push('/conartist/stacketicketusd')}>

                        </StakeLpLocked>
                    </div> */}
                    <div onClick={() => history.push('/play')}>
                        <Back>

                        </Back>
                    </div>
                    {/* <div>
                        <TalkLeft onClick={openModal}></TalkLeft>
                    </div>
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        style={customStyles}
                    >
                        <div>
                            <h2 style={{color: '#FFFFFF'}}>You scratch my back, I’ll scratch yours... <br />
                                <br />
                                If you're willing to lend me some tickets and money, I can make it work for you... <br />
                                <br />
                                High yields with no risk at all, seriously! You can trust me...<br /></h2>
                            <h2 style={{marginTop: '15px', color: '#66605C', cursor: 'pointer'}} onClick={() => { 
                        window.open('https://medium.com/@louvre-finance/the-con-artist-4d01bff605e2', '_blank');}}>Learn more...</h2>
                            <CloseModalButton onClick={closeModal}>X</CloseModalButton>
                        </div>
                    </Modal>
                    <div>
                    <AddMetamask onClick={() => AddCoin()}></AddMetamask>
                    </div> */}
                </FrameContent>
                <FrameBadge>
                    $TICKET Market
                </FrameBadge>

            </Frame>

            <MarketInfo />
        </Container>
    )
}