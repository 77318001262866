import styled from 'styled-components';
import Backdefaut from '../../../assets/buttons/defaut/Back.svg';
import Backpressed from '../../../assets/buttons/pressed/Back.svg';
import AproveLpdefaut from '../../../assets/buttons/defaut/AproveLp.svg';
import AproveLppressed from '../../../assets/buttons/pressed/AproveLp.svg';
import CreatLpdefaut from '../../../assets/buttons/defaut/CreatLp.svg';
import CreatLppressed from '../../../assets/buttons/pressed/CreatLp.svg';
import NoUnstakedefaut from '../../../assets/buttons/defaut/NoUnstake.svg';
import ClaimDisablepressed from '../../../assets/buttons/ClaimApprove.svg';
import StakeLppressed from '../../../assets/buttons/pressed/StakeLp.svg';
import StakeLpdefaut from '../../../assets/buttons/defaut/StakeLp.svg';
import ConnectWalletdefaut from '../../../assets/buttons/defaut/ConnectWallet.svg';
import ConnectWalletpressed from '../../../assets/buttons/pressed/ConnectWallet.svg';
import ClaimApprovedefaut from '../../../assets/buttons/defaut/ClaimApprove.svg';
import ClaimApprovepressed from '../../../assets/buttons/pressed/ClaimApprove.svg';
import TalkImg from '../../../assets/buttons/TalkLeft.svg';
import InterDefaut from '../../../assets/buttons/defaut/Inter.svg';
import InterPressed from '../../../assets/buttons/pressed/Inter.svg';




export const Container = styled.div`
    max-width: 100vw !important;
`;


export const FrameContent = styled.div`
    height: 93%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;
`;

export const TitleOne = styled.h1`
    position: relative;
   width: 338px;
   height: 215px;
   left: calc(50% - 277px/2 + 166.5px);
   top: calc(50% - 215px/2 + 55.5px);

   /* Fanda Eyptian 24 */

   font-family: Fanda Egyptian;
   font-style: normal;
   font-weight: normal;
   font-size: 24px;
   line-height: 24px;

   /* Red */

   color: #6B2434;
`;

export const TextOne = styled.h1`
   position: relative;
   width: 506px;
   height: 72px;
   left: calc(50% - 206px/2 + 131px);
   top: calc(50% - 72px/2 - 129px);

   /* Fanda Egyptian 18 */

   font-family: Fanda Egyptian;
   font-style: normal;
   font-weight: normal;
   font-size: 18px;
   line-height: 18px;

   /* Shadow */

   color: #24103F;
   span {
      color: #6B2434;
  }

`;

export const TextTwo = styled.h1`
    position: relative;
    width: 477px;
   height: 36px;
   left: calc(50% - 277px/2 + 166.5px);
   top: calc(50% - 36px/2 - 109px);

   /* Fanda Egyptian 18 */

   font-family: Fanda Egyptian;
   font-style: normal;
   font-weight: normal;
   font-size: 18px;
   line-height: 18px;

   /* Shadow */

   color: #24103F;
   span {
      color: #6B2434;
  }
`;

export const TitleTwo = styled.h1`
    position: relative;
    width: 357px;
      height: 24px;
      left: calc(50% - 157px/2 + 106.5px);
      top: calc(50% - 24px/2 - 120px);

      /* Fanda Eyptian 24 */

      font-family: Fanda Egyptian;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 24px;
      /* identical to box height */


      /* Red */

      color: #6B2434;
`;

export const ClaimApprove = styled.div`
   background: url(${ClaimApprovedefaut}); 
   position: relative;
   width: 220px;
   height: 118px;
   left: calc(50% - 220px/2 + 89px);
   top: calc(50% - 118px/2 - 99px);
    cursor: pointer;

    :active {
      background: url(${ClaimApprovepressed});
   }
   
`;

export const ClaimDisable = styled.div`
   background: url(${ClaimDisablepressed}); 
   position: relative;
   width: 220px;
   height: 118px;
   left: calc(50% - 220px/2 + 89px);
   top: calc(50% - 118px/2 - 99px);
   cursor: no-drop;
   opacity: 0.8;

`;

export const Back = styled.div`
   background: url(${Backdefaut}); 
    position: relative;
    width: 67px;
    height: 50px;
    left: calc(50% - 298px/2 - 295px);
    top: calc(50% - 50px/2 - 159px);
    cursor: pointer;

    :active {
      background: url(${Backpressed});
   }
   
`;

export const AproveLp = styled.div`
   background: url(${AproveLpdefaut}); 
    position: relative;
    width: 454px;
   height: 50px;
   left: calc(50% - 220px/2 + 90px);
   top: calc(50% - 50px/2 - 16px);
    cursor: pointer;

    :active {
      background: url(${AproveLppressed});
   }
   
`;

export const CreatLp = styled.div`
   background: url(${CreatLpdefaut}); 
    position: relative;
    width: 220px;
   height: 50px;
   left: calc(50% - 220px/2 + 322px);
   top: calc(50% - 50px/2 + 63px);
    cursor: pointer;

    :active {
      background: url(${CreatLppressed});
   }
   
`;

export const NoUnstake = styled.div`
   background: url(${NoUnstakedefaut}); 
    position: relative;
    width: 220px;
   height: 50px;
   left: calc(50% - 220px/2 + 324px);
   top: calc(50% - 50px/2 - 46px);

   opacity: 0.8;
    cursor: no-drop;
   
`;

export const StakeLp = styled.div`
   background: url(${StakeLpdefaut}); 
   position: relative;
   width: 454px;
   height: 50px;
   left: calc(50% - 220px/2 + 90px);
   top: calc(50% - 50px/2 - 16px);
   cursor: pointer;

   :active {
   background: url(${StakeLppressed});
   }


`;

export const ConnectWallet = styled.div`
   background: url(${ConnectWalletdefaut}); 
    position: relative;
    width: 454px;
   height: 50px;
   left: calc(50% - 454px/2 + 207px);
   top: calc(50% - 118px/2 + 19px);
    :active {
      background: url(${ConnectWalletpressed});
   }
   
`;

export const CloseModalButton = styled.button`
   border: 0;
   outline: 0;
   color: #fff;
   background: none;
   position: absolute;
   left: 95.5%;
   font-family: Fanda Egyptian;
   top: 2.5%;
   font-size: 24px;
   cursor: pointer;
`;

export const TalkLeft =styled.div`
   background: url(${InterDefaut});
   position: relative; 
   width: 67px;
   height: 50px;
   left: calc(50% - 49px/2 - 339.5px);
   top: calc(50% - 14px/2 - 229px);
   cursor: pointer;
   :active {
      background: url(${InterPressed});
   }
`;