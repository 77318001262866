import { Container, FrameContent, NextPage, TextOne, TextTwo, TitleOne, TitleTwo } from './styles'
import Button from '../../../components/Button';
import Frame from '../../../components/Frame';
import Navbar from '../../../components/Navbar';
import FrameBadge from '../../../components/FrameBadge';
import MarketInfo from '../../../components/MarketInfo';
import BlueButton from '../ButtonNotebook/Blue'
import { useHistory } from 'react-router-dom';
import RedButton from '../ButtonNotebook/Red';
import OrangeButton from '../ButtonNotebook/Orange';




export default function Notebook() {

    const history = useHistory();

    
    return (
        <Container> 
            <Navbar/>
            <Frame data='Notebook1'>
            
                <FrameContent >
                    <div>
                    <TitleOne>Louvre Finance</TitleOne>
                    </div>
                    <div>
                    <TextOne>There is some movement happening behind the scenes and I feel that the Grand Reopening will happen soon. <br />
                    <br />
                    The Cyber Paris has been absolutelly dull without the great Louvre Exibithions... It's been a while since it closed and it gave me time to plot the perfect plan. </TextOne>
                    </div>

                    <div>
                        <TitleTwo>
                            The Plan
                        </TitleTwo>
                    </div>
                    <div>
                        <TextTwo>
                        GET TICKETS <br />
                        <br />
                        I'll need them to get in the Louvre and steal the artworks. The more the better. <br />
                        <br />
                        The Louvre places extra security on the most valuable pieces, which make them very tricky to steal and need more attempts.
                        </TextTwo>
                    </div>
                    <div onClick={() => history.push('/notebook/3')}>
                        <BlueButton></BlueButton>
                    </div>
                    <div onClick={() => history.push('/notebook/4')}>
                        <RedButton></RedButton>
                    </div>
                    <div onClick={() => history.push('/notebook/2')}>
                        <OrangeButton></OrangeButton>
                    </div>
                    <div onClick={() => history.push('/notebook/2')}>
                        <NextPage>next page -{'>'}</NextPage>
                    </div>
                    
                </FrameContent>
                <FrameBadge>
                    Plans for the Heist
                </FrameBadge>

            </Frame>

            <MarketInfo />
        </Container>
    )
}