import { createGlobalStyle } from 'styled-components';
import backgroundPattern from '../assets/backgroundPattern.svg';
import FandaEgpytian from '../fonts/FandaEgyptian.ttf';
import Alagard from '../fonts/Alagard.ttf';
import NailScratchRegular from '../fonts/NailScratchRegular.ttf';



export default createGlobalStyle`
  body {
    background: url(${backgroundPattern}),  #173D36;
    font-family: 'Alagard', sans-serif;
    max-width: 100vw;

  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  @font-face {
    font-family: 'Fanda Egyptian';
    src: url(${FandaEgpytian}) format('truetype');
    font-style: normal;
  }

  @font-face {
    font-family: 'Alagard';
    src: url(${Alagard}) format('truetype');
    font-style: normal;
  }

  @font-face {
    font-family: 'Nail Scratch';
    src: url(${NailScratchRegular}) format('truetype');
    font-style: normal;
  }
`