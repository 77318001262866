import styled from 'styled-components';

export const Container = styled.div`
    max-width: 100vw !important;
`;


export const FrameContent = styled.div`
    height: 93%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;
`;

export const TitleOne = styled.h1`
    position: relative;
    width: 216px;
    height: 31px;
    left: calc(50% - 216px/2 - 233px);
    top: calc(50% - 31px/2 + 58px);

    /* Alagard */

    font-family: Alagard;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 31px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* Shadow */

    color: #24103F;
`;

export const TextOne = styled.h3`
    position: relative;
    width: 362px;
    height: 346px;
    left: calc(50% - 362px/2 - 179px);
    top: calc(50% - 346px/2 + 87px);

    /* Fanda Egyptian 18 */

    font-family: Fanda Egyptian;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;

    /* Shadow */

    color: #24103F;
`;

export const TitleTwo = styled.h1`
    position: relative;
    width: 122px;
    height: 31px;
    left: calc(50% - 122px/2 + 119px);
    top: calc(50% - 31px/2 - 318px);

    /* Alagard */

    font-family: Alagard;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 31px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* Shadow */

    color: #24103F;
`;

export const TextTwo = styled.h3`
    position: relative;
    width: 362px;
    height: 290px;
    left: calc(50% - 362px/2 + 239px);
    top: calc(50% - 290px/2 - 292px);

    /* Fanda Egyptian 18 */

    font-family: Fanda Egyptian;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;

    /* Shadow */

    color: #24103F;
`;

export const NextPage = styled.h3`
    position: relative;
    width: 129px;
    height: 15px;
    left: calc(50% - 129px/2 + 265.5px);
    top: calc(50% - 15px/2 - 342.5px);
    cursor: pointer;

    /* Fanda Egyptian 18 */

    font-family: Fanda Egyptian;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;

    color: #DCA456;

`;