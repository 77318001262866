import styled from 'styled-components';
import Backdefaut from '../../../../assets/buttons/defaut/Back.svg';
import Backpressed from '../../../../assets/buttons/pressed/Back.svg';
import StealButtonDefault from '../../../../assets/buttons/StealButtonDefault.svg';
import StealButtonPressed from '../../../../assets/buttons/StealButtonPressed.svg';
import StealApproveButtonDefault from '../../../../assets/buttons/defaut/ApproveSteal.svg';
import StealApproveButtonPressed from '../../../../assets/buttons/pressed/ApproveSteal.svg';
import ReturnApproveButtonDefault from '../../../../assets/buttons/defaut/ApReturn.svg';
import ReturnApproveButtonPressed from '../../../../assets/buttons/pressed/ApReturn.svg';
import ReturnButtonDefault from '../../../../assets/buttons/defaut/Return.svg';
import ReturnButtonPressed from '../../../../assets/buttons/pressed/Return.svg';
import ConnectDefault from '../../../../assets/buttons/defaut/ConnectSteal.svg';
import ConnectPressed from '../../../../assets/buttons/pressed/ConnectSteal.svg';
import BuyTicketDefault from '../../../../assets/buttons/defaut/BuyTicketSteal.svg';
import BuyTicketPressed from '../../../../assets/buttons/pressed/BuyTicketSteal.svg';



export const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
`;

export const Back = styled.div`
   background: url(${Backdefaut}); 
    width: 67px;
    height: 50px;
    cursor: pointer;
    position: absolute;
    left: calc(50% - 298px/2 - 287px);
    top: 720px;
    z-index: 13;
    :active {
      background: url(${Backpressed});
   }
   
`; 

export const StealButton = styled.button`
   background: url(${StealButtonDefault}) no-repeat;
   border: 0;
   outline: 0;
   color: #fff;
   margin-left: 40px;
   margin-top: 10px;
   width: 200px;
   height: 50px;
   cursor: pointer;
   font-family: Fanda Egyptian;
   font-size: 24px;
   color: ${({ theme }) => theme.colors.white};
   :active {
      background: url(${StealButtonPressed});
   }
`;

export const StealApproveButton = styled.button`
   background: url(${StealApproveButtonDefault}) no-repeat;
   border: 0;
   outline: 0;
   color: #fff;
   margin-left: 40px;
   margin-top: 10px;
   width: 200px;
   height: 50px;
   cursor: pointer;
   font-family: Fanda Egyptian;
   font-size: 24px;
   color: ${({ theme }) => theme.colors.white};
   :active {
      background: url(${StealApproveButtonPressed});
   }
`;

export const ReturnApproveButton = styled.button`
   background: url(${ReturnApproveButtonDefault}) no-repeat;
   border: 0;
   outline: 0;
   color: #fff;
   margin-left: 40px;
   margin-top: 10px;
   width: 200px;
   height: 50px;
   cursor: pointer;
   font-family: Fanda Egyptian;
   font-size: 24px;
   color: ${({ theme }) => theme.colors.white};
   :active {
      background: url(${ReturnApproveButtonPressed});
   }
`;

export const ReturnButton = styled.button`
   background: url(${ReturnButtonDefault}) no-repeat;
   border: 0;
   outline: 0;
   color: #fff;
   margin-left: 40px;
   margin-top: 10px;
   width: 200px;
   height: 50px;
   cursor: pointer;
   font-family: Fanda Egyptian;
   font-size: 24px;
   color: ${({ theme }) => theme.colors.white};
   :active {
      background: url(${ReturnButtonPressed});
   }
`;

export const Connect = styled.button`
   background: url(${ConnectDefault}) no-repeat;
   border: 0;
   outline: 0;
   color: #fff;
   margin-left: 40px;
   margin-top: 10px;
   width: 200px;
   height: 50px;
   cursor: pointer;
   font-family: Fanda Egyptian;
   font-size: 24px;
   color: ${({ theme }) => theme.colors.white};
   :active {
      background: url(${ConnectPressed});
   }
`;

export const BuyTicket = styled.button`
   background: url(${BuyTicketDefault}) no-repeat;
   border: 0;
   outline: 0;
   color: #fff;
   margin-left: 40px;
   margin-top: 10px;
   width: 200px;
   height: 50px;
   cursor: pointer;
   font-family: Fanda Egyptian;
   font-size: 24px;
   color: ${({ theme }) => theme.colors.white};
   :active {
      background: url(${BuyTicketPressed});
   }
`;

export const ArtworkContainer = styled.div`
   position: absolute;
   top: 297px;
   left: calc(50% - 298px/2 - 7px);
   
   img {
      z-index: 0;
      max-width: 350px;
      max-height: 100%;
   }
`;

export const SlotContainer = styled.div`
    width: 350px;
    height: 407.42px;
    position: absolute;
    top: 375px;
    left: calc(50% - 298px/2 - 317px);
    z-index: 10;

    img {
        width: 350px;
        height: 407.42px;
        justify-content: center;
    }

    span {
        color: #fff;
        font-size: 18px;
        margin: 10px 0;
    }
`;