import React from 'react'
import BigNumber from 'bn.js'
import { AbiItem } from "web3-utils"
import useLocalStorage from '../LocalStorage/useLocalStorage'
import useConnect from './useConnect'
import { BrowserRouter as Router, Link, Switch, Route } from "react-router-dom";


import { ToastContainer, toast, } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import toastImg from '../../assets/Toast.svg'


import web3 from '../utils/web3'
import web3event from '../utils/web3event'
import LART from "../constants/abi/LART.json"
import { Louvre } from '../constants/tokenAddresses'

declare let window: any;


const useLART = () => {
    const { Message } = useConnect()


    const [userWalletAddress, setUserWalletAddress] = useLocalStorage('userAddress', '')
    const [timeMachine, setTimeMachine] = useLocalStorage('timeMachine', 0)
    const [concludMachine, setConcludMachine] = useLocalStorage('concludMachine', false)


    const getLART = (address: string) => {
        const contract = new web3.eth.Contract((LART.abi as unknown) as AbiItem, address)
        return contract
    }

    const getLARTWss = (address: string) => {
      const contract = new web3event.eth.Contract((LART.abi as unknown) as AbiItem, address)
      return contract
    }

    const sleep = (ms: number) => {
        return new Promise((resolve) => setTimeout(resolve, ms));
      };

    const waitTransaction = async (txHash: string) => {
        let txReceipt: any = null;
        while (txReceipt === null) {
          const r = await web3.eth.getTransactionReceipt(txHash);
          txReceipt = r;
          await sleep(2000);
        }
        return txReceipt.status;
      };

    const approveNFT = async (
        spenderAddress: string,
        nftId: number,
        tokenAddress: string,
      ): Promise<boolean> => {
        console.log(userWalletAddress)
        try {
          const tokenContract = getLART(tokenAddress);
          return tokenContract.methods
            .approve(spenderAddress, nftId)
            .send({ from: userWalletAddress, maxPriorityFeePerGas: null,
              maxFeePerGas: null }, async (error: any, txHash: string) => {
              if (error) {
                console.log("ERC20 could not be approved", error);
                return false;
              }
              const status = await waitTransaction(txHash);
              if (!status) {
                console.log("Approval transaction failed.");
                Message([{head : "Don't have", body: `You don't have this NFT, check your switcase, Loser`, variant: 'warning'}])
                return false;
              }
              return true;
            });
        } catch (e) {
          console.log("error", e);
          return false;
        }
      };
    
    const getAllowanceNFT = async (nftId: number, tokenAddress: string): Promise<boolean> => {
    try {
        const tokenContract = getLARTWss(tokenAddress)
        const allowance: string = await tokenContract.methods.getApproved(nftId).call()
        return allowance === Louvre
    } catch (e) {
        return false
    }
    };

    return { 
        getLART,
        getAllowanceNFT,
        approveNFT
      }
}

export default useLART