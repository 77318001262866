import styled from "styled-components";
import marketStats from '../../../../assets/buttons/BackBlue.svg';

export const Background = styled.button`
   background: url(${marketStats}) no-repeat center; 
   position: relative;
    width: 32.89px;
    height: 40px;
    left: calc(50% - 32.89px/2 + 452.44px);
    top: calc(50% - 40px/2 - 549px);
    cursor: pointer;
`;
