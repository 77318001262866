import { FrameContent, ArtworkContainer, ArtworkContent } from './styles'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { ArtworkScroll } from '../../../../components/ArtworkScroll';

import ArtworkButton from '../ArtworkButton';
// swiper bundle styles
import 'swiper/swiper-bundle.min.css'
// swiper core styles
import 'swiper/swiper.min.css'
// modules styles
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'
import { ArtworkContext } from '../../../../context/ArtworkProvider';
import { useContext } from 'react';


SwiperCore.use([Navigation, Pagination]);



export default function ArtworkSlider() {

    const { artworks } = useContext(ArtworkContext);

    return (
        <FrameContent>
            <Swiper
                id="main"
                navigation={{
                    prevEl: '.prev',
                    nextEl: '.next',
                }}
                pagination={{ clickable: true, type: 'custom' }}
                slidesPerView={1}
                onSwiper={swiper => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}

            >
                {artworks.map(artwork => (
                    <SwiperSlide key={artwork.id}>
                        <ArtworkContainer>
                            <ArtworkContent>
                                <span>{artwork.id++}/{artworks.length}</span>
                                <div>
                                    <img src={require(`../../../../assets/Artworks/${artwork.Floor}/${artwork.Title.replace(/ /g, '')}.png`).default} alt={artwork.Title} />
                                </div>
                            </ArtworkContent>
                        </ArtworkContainer>
                        <ArtworkScroll>
                            <p>{artwork.Title}</p>
                            <small>Attempts: 50</small>
                            <small>Robed: 10 times</small>
                            <small>Success rate: 15%</small>
                            <small>Reward: $500.00</small>
                            <ArtworkButton >Get Reward</ArtworkButton>
                            <ArtworkButton>view on OS</ArtworkButton>
                        </ArtworkScroll>
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className="prev"><button className="swiper-button-prev"></button></div>
            <div className="next"><button className="swiper-button-next"></button></div>
        </FrameContent>
    )
}