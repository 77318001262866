import { ButtonContractThen, ButtonFuckIt, Container, FrameContent, InputElement, TalkLeft, InputText, ScrollThieves, CloseModalButton, Back, TextOne, TitleOne, TitleTwo} from './styles'
import Frame from '../../components/Frame';
import Navbar from '../../components/Navbar';
import FrameBadge from '../../components/FrameBadge';
import MarketInfo from '../../components/MarketInfo';
import { useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import { BNtoDecimal } from '../../components/utils/numerals'
import BigNumber from 'bn.js'
import useBalance from '../../components/hooks/useBalance'
import { Ticket, Louvre } from '../../components/constants/tokenAddresses';
import useLocalStorage from '../../components/LocalStorage/useLocalStorage'
import useLouvre from '../../components/hooks/useLouvre';
import Modal from 'react-modal'


interface InputChangeInterface {
    target: HTMLInputElement;
  }

export default function DenOfThieves() {
    const history = useHistory();

    const [hired, setHired] = useLocalStorage('hired', 0)
    const [isLogged, setisLogged] = useLocalStorage('loading', false)
    const [hire, setHire] = React.useState(0)
    const [multiplier, setMultiplier] = React.useState(0)
    const [balanceToken, setBalanceToken] = React.useState<BigNumber>(new BigNumber(0))
    const [modalIsOpen, setIsOpen] = React.useState(false);

    const { getBalanceToken } = useBalance()
    const { multiplierFee } = useLouvre()

    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)'
          },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          background: 'rgba(0, 0, 0, 0.5)'
        },
      };

      function openModal() {
        setIsOpen(true);
      }

    function closeModal() {
        setIsOpen(false);
    }
    
    const handleOnChange = (event: InputChangeInterface) => {
        if(event.target.valueAsNumber === null){
            setHire(0)
        }else{
            if(event.target.valueAsNumber <= 999){
                if(event.target.valueAsNumber >= 0){
                    setHire(event.target.valueAsNumber)
                } else {
                    setHire(0)
                }
            } else {
                setHire(999)
            }
            
        }
      };

    async function handleBalance() {
    if (isLogged) {
    const balance = await getBalanceToken(Ticket)
    setBalanceToken(balance)
    } else {
        return true
    }
    }

    async function fee(hire: number) {
        const multiplier = await multiplierFee(Louvre, hire)
        const bMultiplier = BNtoDecimal(multiplier, new BigNumber(18), 2)
        setMultiplier(Number(bMultiplier)+hire+1)
    }

    const handleSelect = () => {
        setHired(hire)
    }

    React.useEffect(() => {
        handleBalance()
        fee(hired)
        }, [isLogged, hired])
    

    return (
        <Container> 
            <Navbar/>
            <Frame data='DenOfThieves'>
                <FrameContent>
                    <div>
                        <ScrollThieves></ScrollThieves>
                    </div>
                    <div>
                        <TitleOne>The Plot</TitleOne>
                    </div>
                    <div>
                    <TextOne>TICKET balance: <br /> {balanceToken.toString() === '0' ? 
                        '0.00' 
                        : 
                        BNtoDecimal(balanceToken, new BigNumber(18), 6)}
                    <br/>
                    <br />
                     Hired Thieves: <br />
                      {hired === 0 ?  '0 (only me)' : hired}
                      <br />
                      <br />
                    Heist total cost: <br />
                    {multiplier} Tickets
                      </TextOne>
                    </div>
                    <div>
                        <TitleTwo>The Den of Thieves</TitleTwo>
                    </div>
                    <div>
                        <InputText>Hire:</InputText>
                    </div>
                    <div>
                        <InputElement type="number" onChange={handleOnChange}></InputElement>
                    </div>
                    <div>
                        <ButtonContractThen onClick={handleSelect}>

                        </ButtonContractThen>
                    </div>
                    <div>
                        <ButtonFuckIt onClick={() => setHired(0)}></ButtonFuckIt>
                    </div>
                    <div>
                        <Back onClick={() => history.push('/play')}></Back>
                    </div>
                    <div>
                        <TalkLeft onClick={openModal}></TalkLeft>
                    </div>
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        style={customStyles}
                    >
                        <div>
                            <h2 style={{color: '#FFFFFF'}}>Hey mate, do you want more chances when trying to steal Louvre? <br />
                            <br />
                            You can hire some men with me!
                            <br />
                            Each thief that you hire gives you one extra chance of trying to steal... <br />
                            <br />
                            Each thief will also need one extra TICKET, plus some fees that I charge...</h2>
                            <CloseModalButton onClick={closeModal}>X</CloseModalButton>
                        </div>
                    </Modal>
                </FrameContent>
                <FrameBadge>
                    Den of Thieves
                </FrameBadge>

            </Frame>

            <MarketInfo />
        </Container>
    )
}