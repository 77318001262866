import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: Nail Scratch;
    font-style: normal;
    font-weight: normal;
    position: absolute;
    width: 945px;
    height: 539px;
    top: +237px;
    left: calc(50% - 945px/2 - 0px);
    background-color: rgba(0,0,0,0.25);
    z-index: 3;

    h1 {
        font-size: 120px;
        color: #CC2D2D;
        transform: rotate(-15deg);
        position: absolute;
        left: 230px;
        top: 30px;
    }

    h2 {
        font-size: 50px;
        color: #fff;
        transform: rotate(-10deg);
        position: absolute;
        left: 400px;
        top: 310px;
    }
`;