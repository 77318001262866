import { Container } from '../styles'
import Frame from '../../../../../components/Frame';
import Navbar from '../../../../../components/Navbar';
import FrameBadge from '../../../../../components/FrameBadge';
import MarketInfo from '../../../../../components/MarketInfo';
import { useHistory } from 'react-router-dom';
import FloorSlider from '../FloorSlider';
import { FloorContext } from '../../../../../context/FloorProvider';
import { useContext } from 'react';


export default function Floor() {
    const history = useHistory();
    const { floor } = useContext(FloorContext);

    return (
        <Container> 
            <Navbar/>
            <Frame>
                <FloorSlider data={7}/>
                <FrameBadge>
                    The Louvre - Floor 7
                </FrameBadge>
            </Frame>
            <MarketInfo />
        </Container>
    )
}