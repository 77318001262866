import styled from 'styled-components';
import ScrollThievesImg from '../../assets/ScrollThieves.svg';
import SmallTagdefaut from '../../assets/SmallTag.svg';
import GetTicketdefaut from '../../assets/buttons/defaut/GetTicket.svg';
import GetTicketpressed from '../../assets/buttons/pressed/GetTicket.svg';
import Backdefaut from '../../assets/buttons/defaut/Back.svg';
import Backpressed from '../../assets/buttons/pressed/Back.svg';

export const Container = styled.div`
    max-width: 100vw !important;
`;


export const FrameContent = styled.div`
    height: 93%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 70px;
`;

export const ScrollThieves = styled.div`
   background: url(${ScrollThievesImg}); 
   position: relative;
   width: 261px;
   height: 440.22px;
   left: calc(50% - 298px/2 - 317px);
   top: calc(50% - 50px/2 - 195px);

`;

export const TitleOne = styled.h1`
   position: relative;
   width: 357px;
height: 62px;
left: calc(50% - 357px/2 + 268.5px);
top: calc(50% - 62px/2 + 19px);

font-family: Alagard;
font-style: normal;
font-weight: 500;
font-size: 60px;
line-height: 62px;
/* identical to box height */

display: flex;
align-items: center;
text-align: right;

/* Golden */

color: #CA9219;

text-shadow: 0px 4px 0px #6B2434;
`;

export const TextOne = styled.h2`
   position: relative;
   width: 128px;
height: 36px;
left: calc(50% - 128px/2 + 114px);
top: calc(50% - 36px/2 + 18px);

/* Fanda Egyptian */

font-family: Fanda Egyptian;
font-style: normal;
font-weight: normal;
font-size: 32px;
line-height: 36px;
/* identical to box height, or 112% */

display: flex;
align-items: center;
text-align: right;

color: #FFFFFF;
`;

export const TextTwo = styled.h1`
   position: relative;
   width: 315px;
height: 157px;
left: calc(50% - 315px/2 + 289.5px);
top: calc(50% - 157px/2 + 31.5px);

/* Fanda Eyptian 24 */

font-family: Fanda Egyptian;
font-style: normal;
font-weight: normal;
font-size: 24px;
line-height: 24px;
text-align: right;

color: #FFFFFF;

`;

export const InputText = styled.h2`
   position: relative;
   width: 43px;
   height: 18px;
   left: calc(50% - 43px/2 - 411.5px);
   top: calc(50% - 18px/2 - 345px);

   /* Fanda Egyptian 18 */

   font-family: Fanda Egyptian;
   font-style: normal;
   font-weight: normal;
   font-size: 18px;
   line-height: 18px;
   /* identical to box height */

   text-align: right;

   /* Shadow */

   color: #24103F;
`;

export const InputElement = styled.input`
   display: flex;
   flex-direction: row;
   align-items: flex-start;
   padding: 6px;

   position: relative;
   width: 171px;
   height: 30px;
   left: calc(50% - 171px/2 - 300.5px);
   top: calc(50% - 30px/2 - 370px);

   border: 1px solid #FFFFFF;
   box-sizing: border-box;
`;

export const SmallTag =styled.div`
   background: url(${SmallTagdefaut}); 
   position: relative;
   width: 144px;
   height: 32px;
   left: calc(50% - 144px/2 + 215px);
   top: calc(50% - 32px/2 + 110px);

`;

export const ButtonGetTicket =styled.div`
   background: url(${GetTicketdefaut}); 
   position: relative;
   width: 200px;
   height: 50px;
   left: calc(50% - 200px/2 + 212px);
   top: calc(50% - 50px/2 + 335px);
   cursor: pointer;

   :active {
      background: url(${GetTicketpressed});
}

`;


export const Floors = styled.div`
   display: flex;
   flex-direction: column;

   button {
      outline: 0;
      border: 0;
      background: 0;
      position: absolute;
      top: -38%;
      margin-bottom: 30px;

      &:hover {
         cursor: pointer;
      }
   }
   .seven {
      position: relative;
      left: +2px;
      top: -212px;
   }
   .six {
      position: relative;
      left: -140px;
      top: -150px;
   }
   .five {
      position: relative;
      left: -200px;
      top: -150px; 
   }
   .four {
      position: relative;
      left: -260px;
      top: -160px;
   }
   .three {
      position: relative;
      left: -320px;
      top: -165px;
   }
   .two {
      position: relative;
      left: -370px;
      top: -170px;
   }
   .one {
      position: relative;
      left: -420px;
      top: -175px;
   }
`;

export const Back = styled.div`
   background: url(${Backdefaut}); 
    position: relative;
    width: 67px;
    height: 50px;
    left: +420px;
    top: +450px;
    cursor: pointer;

    :active {
      background: url(${Backpressed});
   }
   
`; 