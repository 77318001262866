import React from 'react'
import { useState, useCallback } from 'react';
import { ScrollTickets, ScrollContainer } from './styles';
import useBalance from '../../components/hooks/useBalance'
import { Ticket } from '../constants/tokenAddresses';
import BigNumber from 'bn.js'
import useLocalStorage from '../LocalStorage/useLocalStorage'
import { BNtoDecimal } from '../utils/numerals'

interface IInputDefaultProps {
    title: string
    investHeim: BigNumber
    amountSwapOut: BigNumber
    receiveTokenSelected: string
    setReceiveTokenSelected: React.Dispatch<React.SetStateAction<string>>
  }

export function Scroll(props: any) {
    const [isOpen, setIsOpen] = useState(true);
    const hired = localStorage.getItem('hired')
    const [isLogged, setisLogged] = useLocalStorage('loading', false)

    const [balanceToken, setBalanceToken] = React.useState<BigNumber>(new BigNumber(0))
    const [counter, setCounter] = React.useState(0)
    
    const [userWalletAddress, setUserWalletAddress] = useLocalStorage('userAddress', '')
    const { getBalanceToken } = useBalance()
    
    async function handleBalance() {
        if (isLogged) {
        const balance = await getBalanceToken(Ticket)
        setBalanceToken(balance)
        } else {
            return true
        }
    }
    React.useEffect(() => {
        handleBalance()
        setTimeout(() => setCounter(counter+1), 2000)
        }, [isLogged, counter])
    
//   const  handleClick = useCallback(() => {
//     if (isOpen === false){
//         setIsOpen(true);
        
//     }
//     else if (isOpen === true) {
//         setIsOpen(false)
//     }
//   }, [isOpen]);

    return (
        <>
            <ScrollContainer isOpen={isOpen}>
            <p>The Plot</p><small>ready</small>
            </ScrollContainer>

            { isOpen && (
                <ScrollTickets>
                    <p>TICKET balance:</p>
                    <p>{balanceToken.toString() === '0' ? 
                        '0.000000' 
                        : 
                        BNtoDecimal(balanceToken, new BigNumber(18), 6)}</p>
                    <br/>
                    <p>Hired Thieves:</p>
                    <p>{hired === '0' ?  '0 (only me)' : hired}</p>
                </ScrollTickets>
            )}
        </>
    )
}