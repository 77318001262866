import styled from "styled-components";
import marketStats from '../../../../assets/buttons/Orange.svg';

export const Background = styled.button`
   background: url(${marketStats}) no-repeat center; 
   position: relative;
    width: 24.89px;
    height: 40px;
    left: calc(50% - 24.89px/2 + 449.44px);
    top: calc(50% - 40px/2 - 679px);
    cursor: pointer;
`;
