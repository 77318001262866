
import Frame from '../../../components/Frame';
import FrameBadge from '../../../components/FrameBadge';
import MarketInfo from '../../../components/MarketInfo';
import Navbar from '../../../components/Navbar';
import { Scroll } from '../../../components/Scroll';
import Content from './Content';
import {Container, FrameContent} from './styles';



export default function RobAChance() {

    return (
        <Container> 
            <Navbar/>
            <Frame data="RobAChance">
                <FrameContent>
                <Scroll>
                    
                </Scroll>
                <Content/>
                
                </FrameContent>
                <FrameBadge>
                   Rob a'chance
                </FrameBadge>

            </Frame>
            <MarketInfo />
        </Container>
    )
}